import React, { useState } from 'react';
import GoogleBusinessProfile from '../../../components/GoogleBusinessProfile/GoogleBusinessProfile';
import { authSelector } from '../../../store/slices/auth';
import { useSelector } from 'react-redux';
import './style.css';

const GoogleBusinessProfileSection = () => {
  const { userInfo } = useSelector(authSelector);
  const [isEditing, setIsEditing] = useState(false);

  const handleEditToggle = () => {
    setIsEditing(!isEditing);
  };

  return (
    <div className="card" id="posts-section">
      <div className="card-header pd-y-15 pd-x-20 d-flex align-items-center justify-content-between">
        <h6 className="tx-semibold mg-b-0">Google Business Profile</h6>
        {userInfo?.role === 'superAdmin' ||
        userInfo?.email === 'esther@adlersocial.com' ||
        userInfo?.email === 'christiana@adlersocial.com' ||
        userInfo?.email === 'emily@adlersocial.com' ||
        userInfo?.email === 'zachary@adlersocial.com' ||
        userInfo?.email === 'vasariah@adlersocial.com' ||
        userInfo?.email === 'heather@adlersocial.com' ||
        userInfo?.email === 'stefan@adlersocial.com' ||
        userInfo?.email === 'robin@adlersocial.com' ? (
          <button
            className="btn btn-xs btn-white no-hover-text"
            onClick={handleEditToggle}>
            {isEditing ? 'Cancel Edit' : 'Edit GBP'}
          </button>
        ) : (
          <></>
        )}
      </div>
      <div className="pd-2" style={{ overflowY: 'hidden' }}>
        <GoogleBusinessProfile
          isEditing={isEditing}
          setIsEditing={setIsEditing}
        />
      </div>
    </div>
  );
};

export default GoogleBusinessProfileSection;
