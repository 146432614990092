import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  timeZones,
  daysOrder,
  API_CLIENTS_NAME,
  API_KEY,
  API_THREE_LAST_REVIEWS
} from '../../utils';
import {
  ImageLibraryIcon,
  ScheduledIcon,
  DraftIcon,
  PostsIcon,
  CalendarIcon,
  TimeZoneIcon,
  NotesIcon,
  ReviewsIcon,
  LightIconBlack,
  GoogleBusinessProfileIcon
} from '../../Icons';
import {
  clientsSelector,
  deleteClient,
  getClientById,
  getClients,
  getCountersById,
  updateClient
} from '../../store/slices/clients';
import {
  selectedClientSelector,
  setSelectedClient
} from '../../store/slices/selectedClient';
import { SearchIcon } from '../../Icons';
import { authSelector } from '../../store/slices/auth';
import ImageLibrary from './ImageLibrary/ImageLibrary';
import PostsSection from './PostsSection';
import ReviewsSection from './ReviewsSection';
import Select from 'react-select';
import Swal from 'sweetalert2';
import { getGroups, groupsSelector } from '../../store/slices/groups';
import { postsSelector } from '../../store/slices/posts';
import { unwrapResult } from '@reduxjs/toolkit';
import { useHistory } from 'react-router-dom';
import ScheduleSection from './ScheduleSection';
import DraftSection from './DraftSection';
import NotesSection from './NotesSection';
import './Client.scss';
import axios from 'axios';
import IGTags from '../../components/IGTags/IGTags';
import { Rating } from '@material-ui/lab';
import Switch from '@mui/material/Switch';
import { isArray } from 'underscore';
import Links from './Links';

import LoadingSpinner from '../../pages/Calendar/GallerySection/StockImagesComponents/Partials/LoadingSpinner';

import Sections from './Sections';

// Function to compare two arrays
function comparer(otherArray) {
  return function (current) {
    return (
      otherArray.filter(function (other) {
        return other.groupName === current.groupName;
      }).length === 0
    );
  };
}

function Client({ match }) {
  const history = useHistory();
  const dispatch = useDispatch();
  const [editClient, setEditClient] = useState(false);
  const [clientDetails, setClientDetails] = useState(null);
  const [saveLoading, setSaveLoading] = useState(false);
  const [editedProps, setEditedProps] = useState(null);
  const [viewSelect, setViewSelect] = useState(1);
  const [isDeleting, setIsDeleting] = useState(false);
  const [selectGroupOptions, setSelectGroupOptions] = useState([]);
  const [selectTimeZonesOptions, setSelectTimeZonesOptions] = useState([]);
  const { customerList } = useSelector(clientsSelector);
  const { selectedClient } = useSelector(selectedClientSelector);
  const { loadingPosts } = useSelector(postsSelector);
  const { userInfo } = useSelector(authSelector);
  const { groupsList } = useSelector(groupsSelector);
  const [lastReviews, setLastReviews] = useState([]);
  const [automaticReviews, setAutomaticReviews] = useState(false);

  const standardMergeFields = [
    '~Name',
    '~Address',
    '~City',
    '~Phone',
    '~Website',
    '~BestDish',
    '~BestService',
    '~BestProduct'
  ];
  const btnTagRef = useRef();

  // Styles of search select
  const searchStyle = {
    control: (_, { selectProps: { width } }) => ({
      width: width,
      border: 'none'
    }),
    indicatorsContainer: () => ({
      display: 'none'
    }),
    input: (provided) => ({
      ...provided,
      marginTop: '0.5em'
    })
  };

  // Get groups
  const fetchGroups = async () => {
    try {
      await dispatch(getGroups());
    } catch (error) {
      console.error(error);
    }
  };

  // Fetch client
  const fetchClient = async () => {
    try {
      let res = await dispatch(getClientById({ clientId: selectedClient.id }));
      res = unwrapResult(res);
      setAutomaticReviews(res.automaticReviews);
      setClientDetails(res);
      let resp = await dispatch(getCountersById({ id: selectedClient.id }));
      resp = unwrapResult(resp);
      res['countPosts'] = resp;
      setClientDetails({ ...res, countPosts: resp });
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(() => {
    async function getURLClient() {
      const client = await axios.get(`${API_CLIENTS_NAME}${match.params.id}`, {
        headers: {
          'X-API-KEY': API_KEY,
          Authorization: `bearer ${userInfo.accessToken}`
        }
      });

      if (client.data) {
        dispatch(setSelectedClient(client.data));
      }
      return client;
    }

    getURLClient();
    // eslint-disable-next-line
  }, [match.params.id]);

  // On load
  useEffect(() => {
    if (selectedClient.id) {
      // fetchGroups();
      fetchClient();
      // fetchLastReviews(selectedClient.id);
    }
    // eslint-disable-next-line
  }, [selectedClient]);

  // Set groups dropdown options
  useEffect(() => {
    if (editedProps) {
      var resultA = groupsList.filter(comparer(editedProps.clientGroups));
      resultA = resultA.map((opt) => ({
        value: opt.id,
        label: opt.groupName
      }));
      setSelectGroupOptions(resultA);
    }
    // eslint-disable-next-line
  }, [editedProps, editClient]);

  const fetchLastReviews = async (id) => {
    try {
      let res = await fetch(API_THREE_LAST_REVIEWS, {
        method: 'post',
        body: JSON.stringify({ id: id })
      });
      res = await res.json();
      setLastReviews(JSON.parse(res.body));
    } catch (error) {
      console.error(error);
      setLastReviews([]);
    }
  };
  // Set timezone dropdown options
  useEffect(() => {
    if (editedProps) {
      let listTimeZone;
      if (clientDetails && clientDetails.timeZone) {
        listTimeZone = timeZones
          .filter((item) => item.name !== clientDetails.timeZone)
          .map((zone) => ({
            value: zone.id,
            label: zone.name
          }));
      } else {
        listTimeZone = timeZones.map((zone) => ({
          value: zone.id,
          label: zone.name
        }));
      }
      setSelectTimeZonesOptions(listTimeZone);
    }
    // eslint-disable-next-line
  }, [editedProps, editClient]);

  const customStyles = {
    menu: (provided, state) => ({
      ...provided,
      width: '100%',
      color: state.selectProps.menuColor,
      padding: 10,
      overflow: 'hidden',
      backgroundColor: 'white', // Fondo blanco para el menú
      zIndex: 9999
    }),
    control: (_, { selectProps: { width } }) => ({
      width: width
    }),
    indicatorsContainer: () => ({
      display: 'none'
    }),
    placeholder: (provided) => ({
      ...provided,
      color: '#c3c3c3',
      marginTop: '0.2em'
    }),
    input: (provided) => ({
      ...provided,
      marginTop: '0.5em'
    }),

    singleValue: (provided, state) => {
      const opacity = state.isDisabled ? 0.5 : 1;
      const transition = 'opacity 300ms';

      return { ...provided, opacity, transition };
    }
  };

  const handleSave = async () => {
    try {
      const {
        clientName,
        clientGroups,
        socialnetworks,
        timeZone,
        bestTimes,
        mergeFields,
        automaticReviews
      } = editedProps;
      setSaveLoading(true);

      let newClientName = clientName.replace(/\s+/g, ' ');
      newClientName = newClientName.replace(/^\s+|\s+$/g, '');
      newClientName = newClientName.replace(/’/g, "'");

      let payload = {
        id: selectedClient.id,
        clientName: newClientName,
        oldClientName:
          newClientName !== selectedClient.clientName
            ? selectedClient.clientName
            : '',
        clientGroups: clientGroups,
        groupsDelete: [],
        socialNetworks: socialnetworks,
        timeZone,
        bestTimes,
        mergeFields,
        automaticReviews
      };
      let res = await dispatch(updateClient(payload));
      res = unwrapResult(res);
      if (res) {
        btnTagRef.current.click();
        await fetchClient();
        setSaveLoading(false);
        setEditClient(false);
      }
    } catch (e) {
      setSaveLoading(false);
    }
  };

  // Set label and value for search
  const getCustomerList = () => {
    if (!customerList?.length) {
      dispatch(getClients());
    }

    return customerList.map((customer) => ({
      ...customer,
      label: customer.clientName,
      value: customer.clientName
    }));
  };

  // handle change in select search
  const handleChangeClient = (x) => {
    dispatch(setSelectedClient(x));
    let newUrl = x.clientName
      .replace(/\s+/g, '%20')
      .replace(/\//g, '%2F')
      .replace(/'/g, '%1D')
      .replace(/’/g, '%1E');
    history.push(`/client/${newUrl}`);
  };

  // Handle delete client
  const handleDelete = () => {
    // Find groups the client was part of
    let newGroups = [];
    selectedClient.clientGroups.forEach((clientGroup) => {
      return { id: clientGroup.id };
    });
    Swal.fire({
      title: 'Delete Client',
      text: 'Are you sure you want delete',
      showCloseButton: true,
      showCancelButton: true
    }).then(async (res) => {
      if (res.value) {
        try {
          setIsDeleting(true);
          const payload = {
            groupsDelete: newGroups,
            clientId: selectedClient.id,
            deletedBy: {
              displayName: userInfo?.displayName,
              email: userInfo?.email,
              role: userInfo?.role
            }
          };
          let res = await dispatch(deleteClient(payload));
          res = unwrapResult(res);
          setIsDeleting(false);
          if (res) {
            Swal.fire({
              title: 'Success!',
              text: 'Client Deleted.',
              showCloseButton: false,
              allowOutsideClick: false
            }).then((res) => {
              if (res.value) {
                history.push('/clients');
              }
            });
          } else {
            Swal.fire({
              title: 'Warning!',
              text: 'Client is deleted already.',
              showCloseButton: false,
              allowOutsideClick: false
            }).then((res) => {
              if (res.value) {
                history.push('/clients');
              }
            });
          }
        } catch (e) {
          console.error(e);
        }
      }
    });
  };

  // Client metadata
  const clientMeta = () => {
    return (
      <div className="col-sm-8 col-md-7 col-lg mg-t-20 mg-sm-t-0 mg-lg-t-25">
        <h5 className="mg-b-2 tx-spacing--1">
          {editClient ? (
            <input
              type="text"
              className="form-control"
              placeholder={clientDetails ? clientDetails.clientName : ''}
              value={editedProps.clientName}
              onChange={(e) =>
                setEditedProps({
                  ...editedProps,
                  clientName: e.target.value
                })
              }
            />
          ) : clientDetails ? (
            clientDetails.clientName
          ) : (
            ''
          )}
        </h5>
        <p className="tx-color-03 mb-2">
          {clientDetails &&
            clientDetails.clientGroups.map((g) => `${g.groupName} `)}
        </p>
        <div className="d-flex skill-sets">
          <div className="profile-skillset">
            <h4>
              <button className="button-like-a link-01">
                {clientDetails &&
                clientDetails?.countPosts &&
                clientDetails?.countPosts.Posts
                  ? clientDetails?.countPosts.Posts
                  : '0'}
              </button>
            </h4>
            <label>Posts</label>
          </div>
          <div className="profile-skillset">
            <h4>
              <button className="button-like-a link-01">
                {(clientDetails &&
                  clientDetails.countPosts &&
                  clientDetails['countPosts']['Scheduled']) ||
                  '0'}
              </button>
            </h4>
            <label>Scheduled</label>
          </div>
          <div className="profile-skillset">
            <h4>
              <button className="button-like-a link-01">
                {(clientDetails &&
                  clientDetails.countPosts &&
                  clientDetails['countPosts']['Draft']) ||
                  '0'}
              </button>
            </h4>
            <label>Draft</label>
          </div>
        </div>
      </div>
    );
  };

  // Render client groups
  const clientGroups = () => {
    return (
      <div className="col-sm-6 col-md-5 col-lg mt-2">
        <label className="tx-sans tx-10 tx-semibold tx-uppercase tx-color-01 tx-spacing-1 mg-b-15">
          Groups
        </label>
        {(editClient && (
          <Select
            className="basic-single mb-2"
            value={editedProps.clientGroups.map((cGroup) => ({
              label: cGroup.groupName,
              value: cGroup.id
            }))}
            onChange={(e) =>
              setEditedProps({
                ...editedProps,
                clientGroups: !e
                  ? []
                  : e.map((g) => ({
                      id: g.value,
                      groupName: g.label
                    }))
              })
            }
            options={selectGroupOptions}
            isMulti
          />
        )) || (
          <ul className="list-inline list-inline-skills mb-0">
            {clientDetails &&
              clientDetails.clientGroups.map((group) => (
                <li className="list-inline-item" key={group.id}>
                  <button className="group-button">{group.groupName}</button>
                </li>
              ))}
          </ul>
        )}
      </div>
    );
  };

  const getTimeZones = () => {
    let filteredTimeZone = timeZones.filter(
      (tz) => tz.name === editedProps.timeZone
    )[0];
    return { value: filteredTimeZone.id, label: filteredTimeZone.name };
  };

  // CLient time zones
  const clientTimeZone = () => {
    return (
      <div className="col-sm-6 col-md-5 col-lg mt-2">
        <label className="tx-sans tx-10 tx-semibold tx-uppercase tx-color-01 tx-spacing-1 mg-b-15">
          Time Zone
        </label>
        <ul className="list-unstyled profile-info-list">
          {(editClient && (
            <Select
              className="basic-single"
              value={getTimeZones()}
              onChange={(e) =>
                setEditedProps({
                  ...editedProps,
                  timeZone: e.label
                })
              }
              options={selectTimeZonesOptions}
            />
          )) || (
            <li>
              <TimeZoneIcon />
              <span className="mt-1">
                {clientDetails ? clientDetails.timeZone : ''}
              </span>
            </li>
          )}
        </ul>
      </div>
    );
  };

  // Best posting times
  const clientBestTimePosting = () => {
    const { bestTimes } = clientDetails;
    return (
      <div className="col-sm-6 col-md-5 col-lg mt-2">
        <div className="d-flex align-items-center justify-content-between">
          <h6 className="tx-13 tx-uppercase tx-semibold">Best Post Time</h6>
        </div>
        <ul className="list-inline list-inline-skills mb-2">
          <div className="table-responsive">
            <table className="table table-borderless table-sm tx-nowrap">
              <tbody>
                {Object.keys(bestTimes)
                  .sort((a, b) => daysOrder[a] - daysOrder[b])
                  .map((day, idx) => {
                    return (
                      <tr key={idx}>
                        <td className="text-left">{day}</td>
                        <td className="text-right">
                          {editClient ? (
                            <input
                              className="w-100"
                              type="time"
                              required
                              step="3600"
                              defaultValue={
                                bestTimes ? bestTimes[day] + ':00' : '12:00'
                              }
                              onChange={(event) => {
                                bestTimes[day] = event.target.value.substring(
                                  0,
                                  2
                                );
                              }}
                            />
                          ) : !bestTimes ? (
                            '12:00 PM'
                          ) : bestTimes[day] < 12 ? (
                            (bestTimes[day] === '00' ? '12' : bestTimes[day]) +
                            ':00 AM'
                          ) : bestTimes[day] === '12' ? (
                            '12:00 PM'
                          ) : (
                            bestTimes[day] - 12 + ':00 PM'
                          )}
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>
          </div>
        </ul>
      </div>
    );
  };

  // Switch for automaticReviews
  const clientAutomaticReviews = () => {
    return (
      <>
        <div className="col-sm-6 col-md-5 col-lg mt-2">
          <div className="d-flex align-items-center justify-content-between">
            <h6 className="tx-13 tx-uppercase tx-semibold">
              Automatic Review Replies
            </h6>
          </div>
          <div className="d-flex flex-column">
            <Switch
              disabled={!editClient}
              checked={automaticReviews}
              onChange={(e) => {
                setAutomaticReviews(e.target.checked);
                setEditedProps({
                  ...editedProps,
                  automaticReviews: e.target.checked
                });
              }}
              size="small"
              color={'primary'}
            />
          </div>
        </div>
      </>
    );
  };

  //GMB Last Reviews
  const clientLastReviews = () => {
    const RATE = { FIVE: 5, FOUR: 4, THREE: 3, TWO: 2, ONE: 1 };

    return (
      <>
        <div className="col-sm-6 col-md-5 col-lg mt-2">
          <div className="d-flex align-items-center justify-content-between">
            <h6 className="tx-13 tx-uppercase tx-semibold">last gmb reviews</h6>
          </div>
          <div className="d-flex flex-column">
            {lastReviews !== undefined &&
            isArray(lastReviews) &&
            lastReviews.length > 0 ? (
              lastReviews.map((user, index) => {
                return (
                  <div
                    className="ml-2 d-flex flex-row justify-content-between"
                    key={index}>
                    <p>{user.name}</p>
                    <Rating
                      size="medium"
                      name="half-rating-read"
                      defaultValue={RATE[user.star]}
                      readOnly
                    />
                  </div>
                );
              })
            ) : (
              <div className="ml-2">
                <p>No Reviews</p>
              </div>
            )}
          </div>
        </div>
      </>
    );
  };

  // Left sidebar
  const renderProfileSidebar = () => (
    <div className="profile-sidebar pl-3 pr-0">
      <div className="row row-info-client">
        {clientMeta()}
        <br />

        <br />
        <div
          style={{
            display: 'flex',
            justifyContent: 'center'
          }}>
          {customerList.length === 0 && <LoadingSpinner />}

          <label className="tx-sans tx-12 tx-medium tx-color-03">
            {customerList.length > 0
              ? `${
                  customerList.findIndex(
                    (customer) => customer.id === selectedClient.id
                  ) + 1
                }/${customerList.length}`
              : null}
          </label>
        </div>
        {customerList.length > 0 && (
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-evenly'
            }}>
            {customerList.findIndex(
              (customer) => customer.id === selectedClient.id
            ) > 0 && (
              <label
                className="tx-sans tx-12 tx-medium tx-color-03"
                style={{ cursor: 'pointer' }}
                onClick={() => {
                  let x = customerList.findIndex(
                    (customer) => customer.id === selectedClient.id
                  );

                  handleChangeClient(customerList[x - 1]);
                }}>
                {'<-previous'}
              </label>
            )}
            {customerList.findIndex(
              (customer) => customer.id === selectedClient.id
            ) <
              customerList.length - 1 && (
              <label
                className="tx-sans tx-12 tx-medium tx-color-03 "
                style={{ cursor: 'pointer' }}
                onClick={() => {
                  let x = customerList.findIndex(
                    (customer) => customer.id === selectedClient.id
                  );

                  handleChangeClient(customerList[x + 1]);
                }}>
                {'next->'}
              </label>
            )}
          </div>
        )}
        <Links selectedClient={selectedClient} userInfo={userInfo} />
        {clientTimeZone()}
        {clientDetails && clientBestTimePosting()}
        {userInfo.role === 'superAdmin' && clientAutomaticReviews()}
        {clientLastReviews()}
      </div>
    </div>
  );

  // Right sidebar
  const renderRightSidebar = () => {
    const {
      id,
      clientSocialNetworks: { instagram }
    } = selectedClient;
    // HERE HASHTAGS
    return (
      <div className="profile-right-sidebar pl-4 pr-4">
        <div className="row row-info-client">
          {exploreSection()}
          {clientGroups()}
          {clientMergeFieldsTable()}
          <IGTags
            idClient={id}
            show={editClient}
            btnTagRef={btnTagRef}
            handleShow={() => {
              setEditClient(!editClient);
              setEditedProps(clientDetails);
            }}
            instagram={instagram}
          />
        </div>
      </div>
    );
  };

  //Explore section
  const exploreSection = () => {
    return (
      <div className="col-sm-6 col-md-5 col-lg mt-2 section-explore">
        <nav className="nav nav-classic tx-13">
          <button
            onClick={() => setViewSelect(1)}
            className={`${viewSelect === 1 ? 'expl-selected' : ''} nav-link`}>
            <ImageLibraryIcon />
            <span>Image Library</span>
          </button>
          <button
            onClick={() => setViewSelect(2)}
            className={`${viewSelect === 2 ? 'expl-selected' : ''} nav-link`}>
            <PostsIcon />
            <span>Posts</span>
          </button>
          <button
            onClick={() => setViewSelect(3)}
            className={`${viewSelect === 3 ? 'expl-selected' : ''} nav-link`}>
            <ScheduledIcon />
            <span>Scheduled</span>
          </button>
          <button
            onClick={() => setViewSelect(4)}
            className={`${viewSelect === 4 ? 'expl-selected' : ''} nav-link`}>
            <DraftIcon />
            <span>Draft</span>
          </button>
          <button
            className={`nav-link`}
            onClick={() => {
              dispatch(setSelectedClient(selectedClient));
              // Push to Calendar view
              let newUrl = selectedClient.clientName
                .replace(/\s+/g, '%20')
                .replace(/\//g, '%2F')
                .replace(/'/g, '%1D')
                .replace(/’/g, '%1E');
              history.push(`/calendar/${newUrl}`);
            }}>
            <CalendarIcon />
            <span>Calendar</span>
          </button>
          <button
            onClick={() => setViewSelect(5)}
            className={`${viewSelect === 5 ? 'expl-selected' : ''} nav-link`}>
            <NotesIcon />
            <span>Notes</span>
          </button>
          <button
            onClick={() => setViewSelect(6)}
            className={`${viewSelect === 6 ? 'expl-selected' : ''} nav-link`}>
            <ReviewsIcon />
            <span>Reviews</span>
          </button>
          <button
            onClick={() => setViewSelect(7)}
            className={`${viewSelect === 7 ? 'expl-selected' : ''} nav-link`}>
            <LightIconBlack />
            <span>Content Strategies</span>
          </button>
          <button
            onClick={() => setViewSelect(8)}
            className={`${viewSelect === 8 ? 'expl-selected' : ''} nav-link`}>
            <GoogleBusinessProfileIcon />
            <span>GBP</span>
          </button>
        </nav>
      </div>
    );
  };

  // Client merge fields table
  const clientMergeFieldsTable = () => {
    return (
      <div className="col-sm-6 col-md-5 col-lg mt-3 merge-fields-table">
        <div className="d-flex align-items-center justify-content-between">
          <h6 className="tx-13 tx-spacng-1 tx-uppercase tx-semibold mb-2">
            Merge fields
          </h6>
          <button
            className="button-like-a link-03"
            onClick={() => {
              setEditClient(!editClient);
              setEditedProps(clientDetails);
            }}>
            {editClient ? 'Cancel' : 'Edit'}
          </button>
        </div>
        <ul className="list-inline list-inline-skills mb-0">
          <div className="table-responsive">
            <table className="table table-borderless table-sm tx-nowrap">
              <tbody>
                {clientDetails &&
                  clientDetails.mergeFields &&
                  standardMergeFields.map((field, idx) => {
                    return (
                      <tr key={idx}>
                        <td className="text-left">{field}</td>
                        <td className="text-left">
                          {editClient ? (
                            <input
                              type="text"
                              className="form-control"
                              placeholder={field}
                              defaultValue={clientDetails.mergeFields[field]}
                              onChange={(event) => {
                                clientDetails.mergeFields[field] =
                                  event.target.value.trim();
                              }}
                            />
                          ) : (
                            clientDetails.mergeFields[field]
                          )}
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>
          </div>
        </ul>
      </div>
    );
  };

  return (
    <>
      <div className="content ht-100v pd-0">
        <div className="content-header">
          <div className="content-search">
            <SearchIcon />
            <Select
              styles={customStyles}
              className="form-control"
              type="search"
              placeholder="Search clients"
              onChange={(client) => handleChangeClient(client)}
              options={getCustomerList()}
              hideSelectedOptions={true}
              controlShouldRenderValue={false}
            />
          </div>
        </div>

        <div>
          <Sections
            clientDetails={clientDetails}
            selectedClient={selectedClient}
            viewSelect={viewSelect}
            breadcrumbs={[
              { name: 'Clients', link: '/clients' },
              { name: 'Account', link: '#' }
            ]}
          />
        </div>
      </div>
    </>
  );
}

export default Client;
// {/* <div className="client-profile">

//   <div className="content content-fixed content-profile p-0">
//     <div className="df-table shadow-sm">
//       <div className="search-form">
//         <SearchIcon />
//         <Select
//           styles={searchStyle}
//           className="client-search"
//           type="search"
//           placeholder="Search clients"
//           onChange={(client) => handleChangeClient(client)}
//           options={getCustomerList()}
//           hideSelectedOptions={true}
//           controlShouldRenderValue={false}
//         />
//       </div>
//       <div className="mt-4 mr-4">
//         {(userInfo.role === 'admin' || userInfo.role === 'superAdmin') && (
//           <div className="d-flex mg-b-25">
//             {!editClient ? (
//               <button
//                 onClick={() => {
//                   setEditClient(!editClient);
//                   setEditedProps(clientDetails);
//                 }}
//                 className="btn btn-xs btn-white flex-fill">
//                 Edit
//               </button>
//             ) : (
//               <ButtonLoad
//                 state={saveLoading}
//                 text={' Save'}
//                 icon={'fa-save'}
//                 btn={'btn btn-xs btn-white flex-fil'}
//                 type={'submit'}
//                 callback={handleSave}
//               />
//             )}
//             <button
//               onClick={
//                 editClient
//                   ? () => {
//                       setEditClient(!editClient);
//                       setEditedProps(null);
//                     }
//                   : () => handleDelete()
//               }
//               className="btn btn-xs btn-primary flex-fill mg-l-10 delete-btn"
//               disabled={isDeleting}>
//               {editClient ? 'Cancel' : 'Delete'}
//             </button>
//           </div>
//         )}
//       </div>
//     </div>
//     <div
//       className="container pd-x-0 pd-lg-x-10 pd-xl-x-0"
//       style={{ paddingTop: '54px' }}>
//       <div className="media d-block d-lg-flex">
//         {renderProfileSidebar()}
//         {renderContent()}
//         {renderRightSidebar()}
//       </div>
//     </div>
//   </div>
// </div> */}
