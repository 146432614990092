import React from 'react';
import { useSelector } from 'react-redux';
import {
  RENDER_DEV,
  REPORTS_FLAG,
  NEW_ONBOARDING_ENABLED,
  SHOW_LOGS
} from '../../utils';
import { authSelector } from '../../store/slices/auth';
import LogOut from '../../components/LogOut';
import { Link, useLocation, useHistory } from 'react-router-dom';
import './Header.scss';
import { AdlerIcon } from '../../Icons';
import { NotificationsGrid } from '../../components/Notifications';

function Header({ loading }) {
  const { userInfo } = useSelector(authSelector);
  const location = useLocation();
  const history = useHistory();

  const checkActive = (route) => (location.pathname === route ? 'active' : '');
  const allowedDomains = ['@samxlabs.com'];

  return (
    <>
      <header
        className={`navbar navbar-header navbar-header-fixed ${
          RENDER_DEV === 'true' ? 'bg-development' : 'bg-production'
        }`}>
        <div
          className="navbar-brand"
          style={{ cursor: 'pointer' }}
          onClick={() => history.push('/clients')}>
          <AdlerIcon className="w-75" />
        </div>
        <div id="navbarMenu" className="navbar-menu-wrapper">
          <ul className="nav navbar-menu">
            <li className={`nav-item ${checkActive('/clients')}`}>
              <Link to="/clients" className="nav-link ">
                Clients
              </Link>
            </li>
            <li className={`nav-item ${checkActive('/groups')}`}>
              <Link to="/groups" className="nav-link">
                Groups
              </Link>
            </li>
            <li className={`nav-item ${checkActive('/reviews')}`}>
              <Link to="/reviews" className="nav-link ">
                {/* <Badge badgeContent={1} color="error"> */}
                Reviews
                {/* </Badge> */}
              </Link>
            </li>
            {REPORTS_FLAG === 'ON' && (
              <li className={`nav-item ${checkActive('/assignment')}`}>
                <Link to="/assignment" className="nav-link">
                  Assignments
                </Link>
              </li>
            )}
            {(userInfo?.role === 'superAdmin' ||
              userInfo?.email === 'esther@adlersocial.com' ||
              userInfo?.email === 'christiana@adlersocial.com' ||
              userInfo?.email === 'emily@adlersocial.com' ||
              userInfo?.email === 'zachary@adlersocial.com' ||
              userInfo?.email === 'vasariah@adlersocial.com' ||
              userInfo?.email === 'heather@adlersocial.com') &&
            NEW_ONBOARDING_ENABLED ? (
              <li className={`nav-item ${checkActive('/content-strategies')}`}>
                <Link to="/content-strategies" className="nav-link">
                  Content
                </Link>
              </li>
            ) : null}
            <li className={`nav-item ${checkActive('/images')}`}>
              <Link to="/images" className="nav-link">
                Images
              </Link>
            </li>
          </ul>
        </div>
        <div className="navbar-right">
          <NotificationsGrid />

          <div className="dropdown dropdown-profile">
            <a
              href="dashboard-four.html"
              className="dropdown-link"
              data-toggle="dropdown"
              data-display="static">
              <div className="avatar avatar-sm avatar-online">
                <img
                  src={userInfo.photoURL}
                  className="rounded-circle"
                  alt="profile"
                />
              </div>
            </a>
            <div className="dropdown-menu dropdown-menu-right tx-13">
              <div className="avatar avatar-lg mg-b-15">
                <img
                  src={userInfo.photoURL}
                  className="rounded-circle"
                  alt="profile"
                />
              </div>
              <h6 className="tx-semibold mg-b-5">{userInfo.displayName}</h6>
              <p className="mg-b-5">
                {userInfo.role === 'admin' ? 'Administrator' : userInfo.role}
              </p>
              <p className="mg-b-25 tx-12 tx-color-03">{userInfo.email}</p>
              <div className="dropdown-divider" />
              <LogOut />
            </div>
          </div>
        </div>
      </header>

      {loading && (
        <div
          className="progress position-fixed w-100"
          style={{
            height: '3px',
            left: 0,
            zIndex: 1000,
            borderRadius: 0
          }}>
          <div className="progress-bar indeterminate" />
        </div>
      )}
    </>
  );
}

export default Header;
