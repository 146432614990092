import React, {
  createRef,
  useCallback,
  useEffect,
  useState,
  forwardRef
} from 'react';
import { useSelector } from 'react-redux';
import Select, { components } from 'react-select';
import { API_FACEBOOK_PAGES, API_RESOLVE_FAILING_SN } from '../utils/constants';
import Swal from 'sweetalert2';
import ButtonLoad from './ButtonLoad';
import { authSelector } from '../store/slices/auth';

const axios = require('axios');
const { Option } = components;
const CustomSelectOption = (props) => (
  <Option {...props}>
    <span
      style={{ color: props.data.icon === 'times' ? 'firebrick' : 'green' }}>
      <i className={`fas fa-${props.data.icon}-circle`} />
    </span>
    &nbsp;
    {props.data.label}
  </Option>
);

const NewFacebookPageConnection = forwardRef((props, ref) => {
  const { isAuthenticated, userInfo } = useSelector(authSelector);
  const [optionsData, setOptionsData] = useState([]);
  const [facebookPage, setFacebookPage] = useState(null);
  const [currentClient, setCurrentClient] = useState(null);
  const [savingClient, setSavingClient] = useState(false);
  const [isFailing, setIsFailing] = useState(false);
  const [modalDismiss, setModalDismiss] = useState(createRef());
  const [isLoading, setIsLoading] = useState(false);

  const handleResolved = async (snName) => {
    let config = {
      method: 'post',
      url: API_RESOLVE_FAILING_SN,
      data: { clientId: props.currentClientId, snName },
      headers: { 'Content-Type': 'application/json' }
    };

    if (isFailing) {
      setIsLoading(true);
      await axios(config)
        .then(async (response) => {
          if (response.status === 200) {
            setIsFailing(false);
            setIsLoading(false);
            await someFn();
          }
        })
        .catch((error) => {
          console.error(error);
          setIsLoading(false);
        });
    }
  };

  useEffect(() => {
    if (props?.currentClient?.clientSocialNetworks?.fbFailing !== undefined) {
      setIsFailing(props?.currentClient?.clientSocialNetworks?.fbFailing);
    } else {
      setIsFailing(false);
    }
  }, [props.currentClient, props.currentClientId]);

  const getPages = async () => {
    setIsLoading(true);
    await axios
      .get(API_FACEBOOK_PAGES, {
        headers: {
          Authorization: `bearer ${userInfo.accessToken}`
        }
      })
      .then((response) => {
        const pagesData = response.data.pages;
        setOptionsData(pagesData);
        setIsLoading(false);
      })
      .catch((error) => {
        console.error('error ' + error);
        throw new Error(error);
      });
  };

  useEffect(() => {
    if (optionsData?.length === 0 && !isLoading) {
      getPages();
    }
  }, []);

  const handleSelectChanges = (option) => {
    if (option) {
      const options = { id: option.value, entity_name: option.label };
      setFacebookPage(options);
      setCurrentClient(props.currentClientId);
    }
  };

  const facebookPageConnectionHandler = () => {
    setSavingClient(true);
    if (facebookPage == null) {
      Swal.fire({
        title: 'Error!',
        text: 'Please select a page.'
      }).then((r) => {
        setSavingClient(false);
      });
      return;
    }
    const bodyPut = {
      clientId: currentClient,
      facebookPageId: facebookPage.id,
      facebookPageName: facebookPage.entity_name
    };

    const url = API_FACEBOOK_PAGES;

    axios
      .put(url, bodyPut, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `bearer ${userInfo.accessToken}`
        }
      })
      .then(async function (response) {
        if (response.status === 200) {
          await someFn();
          setTimeout(() => {
            setSavingClient(false);
            setFacebookPage(null);
            setOptionsData(null);
            modalDismiss.click();
          }, 6000);
        }
      })
      .catch(function (response) {
        setSavingClient(false);
        setFacebookPage(null);
      });
  };

  const someFn = async () => {
    await props.connectSnCallback();
  };

  // if (!loaded) {
  //   return '';
  // }

  return (
    <div>
      <div
        className="modal fade"
        id="newFacebookPageConnection"
        tabIndex={-1}
        role="dialog"
        aria-labelledby="addNewClientLabel"
        aria-hidden="true"
        ref={ref}>
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content tx-14">
            <div className="modal-header">
              <h6 className="modal-title" id="addNewClientLabel">
                Connect Client Facebook Page
              </h6>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"></button>
            </div>
            <div className="modal-body">
              <form>
                <div className="form-group">
                  <div className={'content-title-entitys'}>
                    <label htmlFor="formGroupExampleInput" className="d-block">
                      What's the client facebook page?
                    </label>
                    <span className={'counter-entity'}>
                      {optionsData?.length}
                    </span>
                  </div>
                  <Select
                    options={optionsData?.map((option, idx) => ({
                      value: option.id,
                      label: option.entity_name
                    }))}
                    components={{ Option: CustomSelectOption }}
                    onChange={handleSelectChanges}
                    isSearchable
                    isClearable
                  />
                </div>
              </form>
              {isAuthenticated &&
                userInfo.role === 'superAdmin' &&
                (isFailing &&
                props?.currentClient?.clientSocialNetworks?.facebookPage ? (
                  <div
                    style={{
                      textAlign: 'center',
                      color: isLoading ? 'black' : 'red'
                    }}>
                    <p style={{ verticalAlign: 'middle', display: 'inline' }}>
                      Mark as resolved{' '}
                    </p>
                    <input
                      style={{
                        verticalAlign: 'middle',
                        width: '19px',
                        height: '19px'
                      }}
                      type="checkbox"
                      id="customCheck4"
                      onChange={() => {
                        handleResolved('facebook');
                      }}
                      value={!isFailing}
                      disabled={isLoading}
                    />{' '}
                    if the social network is working again
                    {isLoading ? (
                      <div
                        className="spinner-border spinner-border-sm"
                        role="status">
                        <span className="sr-only">Loading...</span>
                      </div>
                    ) : (
                      <span style={{ fontSize: '20px' }}> ⚠️ </span>
                    )}
                  </div>
                ) : null)}
            </div>

            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary tx-13"
                data-bs-dismiss="modal"
                ref={(button) => {
                  setModalDismiss(button);
                }}>
                Close
              </button>
              <ButtonLoad
                state={savingClient}
                btn={'btn-primary'}
                icon={'fa-save'}
                text={' Save Client'}
                callback={() => {
                  facebookPageConnectionHandler();
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
});

export default NewFacebookPageConnection;
