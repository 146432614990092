import React, { useState, useEffect } from 'react';

const RepeatsOn = (props) => {
  const [checkedItems, setCheckedItems] = useState({});
  const [weekDays, setWeekDays] = useState([]);

  useEffect(() => {
    props.weekDays(weekDays);
    //eslint-disable-next-line
  }, [weekDays]);

  const handleChange = (event) => {
    event.persist();
    setCheckedItems({
      ...checkedItems,
      [event.target.name]: event.target.checked
    });
    if (event.target.checked) {
      setWeekDays((oldArr) => [...oldArr, event.target.value]);
    } else {
      setWeekDays(weekDays.filter((item) => item !== event.target.value));
    }
  };

  const checkboxes = [
    {
      name: 'monday',
      value: 1,
      label: 'M'
    },
    {
      name: 'tuesday',
      value: 2,
      label: 'T'
    },
    {
      name: 'wednesday',
      value: 3,
      label: 'W'
    },
    {
      name: 'thursday',
      value: 4,
      label: 'T'
    },
    {
      name: 'friday',
      value: 5,
      label: 'F'
    },
    {
      name: 'saturday',
      value: 6,
      label: 'S'
    },
    {
      name: 'sunday',
      value: 7,
      label: 'S'
    }
  ];

  return (
    <div style={{ minWidth: '220px' }} className="repeatOn-section">
      <h6 className="card-subtitle mb-2 text-muted">Repeat on:</h6>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center'
        }}
        className="custom-control-inline">
        {checkboxes.map((item, idx) => (
          <label style={{ marginRight: '1px', marginLeft: '2px' }} key={idx}>
            {item.label}
            <input
              style={{ marginLeft: '1px', marginRight: '1px' }}
              type="checkbox"
              name={item.name}
              value={item.value}
              checked={checkedItems[item.name]}
              onChange={handleChange}
            />
          </label>
        ))}
      </div>
    </div>
  );
};

export default RepeatsOn;
