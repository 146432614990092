import React, { forwardRef } from 'react';

import './UserGallery.css';

import '../Modal-calendar.css';

import UserGallery from './UserGallery';

const UserGalleryModal = forwardRef((props, refModal) => {
  return (
    <div>
      <div className="modal fade" tabIndex={-1} role="dialog" ref={refModal}>
        <div className="modal-dialog" role="document">
          <div className={`modal-content`}>
            <div className="modal-header"></div>
            <div className="modal-body">
              <div>
                <UserGallery
                  userId={props.userId}
                  clientName={props.clientName}
                  refresh={props.refresh}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
});

export default UserGalleryModal;
