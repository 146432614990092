import React, { useState, useEffect } from 'react';
import Swal from 'sweetalert2';
import Mobile from './Mobile';
import Switch from '@mui/material/Switch';
import ButtonLoad from '../ButtonLoad';
import {
  APP_REGISTER,
  APP_GET_CLIENT,
  API_KEY,
  REBRANDLY_APIKEY,
  MMS_GET_IMAGES,
  AWS_BUCKET_MMS,
  AWS_MMS_S3_ACCESS_KEY,
  AWS_MMS_S3_API_KEY,
  AWS_REGION,
  API_UPLOAD_S3
} from '../../utils/constants';
import S3 from 'react-aws-s3';
import { v4 as uuid } from 'uuid';
import './style.css';

const axios = require('axios');

const AppRegister = (props) => {
  const [clientInfo, setClientInfo] = useState({});
  const [email, setEmail] = useState('');
  const [emailStatus, setEmailStatus] = useState('form-control');
  const [passwordStatus, setPasswordStatus] = useState('form-control');
  const [messageStatus, setMessageStatus] = useState('form-control');
  const [password, setPassword] = useState('myreviewapp');
  const [password2, setPassword2] = useState('myreviewapp');
  const [message, setMessage] = useState(
    '#name, we appreciate you choosing #company. Please help us spread the word about our services by using the review links below.'
  );
  const [copyData, setCopyData] = useState(null);
  const [clientImages, setClientImages] = useState(null);
  const [savingClient, setSavingClient] = useState(false);
  const [files, setFiles] = useState({ length: 0 });
  const [uploadingImages, setUploadingImages] = useState(false);
  const [gmbLink, setGmbLink] = useState({ long: '', short: '' });
  const [yelpLink, setYelpLink] = useState({ long: '', short: '' });
  const [featureFlags, setFeatureFlags] = useState({
    notifications: true
  });
  const [loadingData, setLoadingData] = useState(false);
  const [longGmb, setLongGmb] = useState(null);
  const [longYelp, setLongYelp] = useState(null);
  const [typePass, setTypePass] = useState('password');
  const [gmbShortId, setGmbShortId] = useState('');
  const [yelpShortId, setYelpShortId] = useState('');
  const [selectedImage, setSelectedImage] = useState(null);
  const [progress, setProgress] = useState(0);

  const config = {
    dirName: `${props.data.id}` /*optional */,
    bucketName: AWS_BUCKET_MMS,
    region: AWS_REGION,
    accessKeyId: AWS_MMS_S3_API_KEY,
    secretAccessKey: AWS_MMS_S3_ACCESS_KEY,
    s3Url: `https://${AWS_BUCKET_MMS}.s3.${AWS_REGION}.amazonaws.com`
  };

  // ===============  SET CLIENT INFO ================

  useEffect(() => {
    if (props?.data?.appActive) {
      const getClient = async (uid) => {
        setLoadingData(true);
        let res = await axios
          .get(`${APP_GET_CLIENT}?uid=${uid}`, {
            headers: {
              'X-API-KEY': `${API_KEY}`
            }
          })
          .then(async (res) => {
            return res;
          })
          .catch((err) => console.error(err));
        if (res.status === 200) {
          const {
            email,
            gmbLongLink,
            gmbShortLink,
            yelpLongLink,
            yelpShortLink,
            message,
            featureFlags
          } = res?.data?.body[0];

          if (res?.data?.body[0]?.selectedImage) {
            setSelectedImage(res?.data?.body[0]?.selectedImage);
          }
          if (gmbShortLink) {
            let shortlinkgmb = gmbShortLink.split('/');
            let idGmb = await axios.get(
              'https://api.rebrandly.com/v1/links?slashtag=' +
                shortlinkgmb[1] +
                '&domain.fullName=' +
                shortlinkgmb[0],
              {
                headers: {
                  'Content-Type': 'application/json',
                  apikey: REBRANDLY_APIKEY
                  // workspace: REBRANDLY_WORKSPACE,
                }
              }
            );
            setGmbShortId(idGmb.data[0].id);
          }
          if (yelpShortLink) {
            let shortlinkyelp = yelpShortLink.split('/');
            let idYelp = await axios.get(
              'https://api.rebrandly.com/v1/links?slashtag=' +
                shortlinkyelp[1] +
                '&domain.fullName=' +
                shortlinkyelp[0],
              {
                headers: {
                  'Content-Type': 'application/json',
                  apikey: REBRANDLY_APIKEY
                  // workspace: REBRANDLY_WORKSPACE,
                }
              }
            );
            setYelpShortId(idYelp.data[0].id);
          }
          setGmbLink({ long: gmbLongLink, short: gmbShortLink });
          setYelpLink({ long: yelpLongLink, short: yelpShortLink });
          setLong({ gmb: gmbLongLink, yelp: yelpLongLink });
          setMessage(message);
          setEmail(email);
          setClientInfo(props.data);
          setFeatureFlags(featureFlags); // HERE
        } else {
          setClientInfo(props.data);
        }
        setLoadingData(false);
      };
      getClient(props.data.appUid);
    } else {
      setClientInfo(props.data); //   setFeatureFlags({ notifications: true });
    }
  }, [props.data]);

  // =============== UPLOAD IMAGES==============
  const renderImages = (files) => {
    let i = 0;

    let itemsList = [];
    while (i < files.length) {
      itemsList[i] = files[i];

      i++;
    }
    itemsList = itemsList.map((item, index) => {
      function returnFileSize(number) {
        if (number < 1024) {
          return number + 'bytes';
        } else if (number >= 1024 && number < 1048576) {
          return (number / 1024).toFixed(1) + 'KB';
        } else if (number >= 1048576) {
          return (number / 1048576).toFixed(1) + 'MB';
        }
      }
      function handleName(name) {
        if (name.length > 35) {
          return name.slice(0, 35) + ' ...';
        } else return name;
      }
      return (
        <div
          key={index}
          className="card"
          style={{
            width: '48%',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between'
          }}>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              marginLeft: 5,
              marginTop: 5,
              overflow: 'hidden'
            }}>
            <b>FileName: </b>
            <u>{handleName(item.name)}</u>
            <b>Size: </b>
            {returnFileSize(item.size)}
          </div>
          <div>
            <img
              alt=""
              src={URL.createObjectURL(item)}
              style={{ width: 100, height: 100, objectFit: 'cover' }}
            />
          </div>
        </div>
      );
    });

    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          flexWrap: 'wrap',
          flexFlow: 'row wrap',
          alignContent: 'flex-end',
          justifyContent: 'space-between'
        }}>
        {itemsList}
      </div>
    );
  };
  // ============== GET IMAGES =================
  const getImages = async (get = false) => {
    let imgs = copyData;
    if (copyData === null || get) {
      imgs = await axios.get(MMS_GET_IMAGES + `userId=${props.data.id}`);
      if (imgs.data.body === 'empty') {
        setSelectedImage(null);
        setClientImages(null);
        return;
      }
      imgs = imgs.data.body;
      setCopyData(imgs);
    }
    let copy2 = imgs;
    imgs = imgs.map((image, index) => {
      let url = image.url;
      return (
        <div
          key={index}
          onClick={() => {
            if (url === selectedImage) {
              setSelectedImage(null);
            } else {
              setSelectedImage(image.url);
            }
          }}
          style={{
            width: '49%',
            display: 'flex',
            flexDirection: 'row',

            border:
              url === selectedImage //URL OF THE IMAGE
                ? '5px solid #567CC8'
                : '5px solid transparent',
            filter: url === selectedImage ? 'grayscale(0)' : 'grayscale(1)',
            transition: '200ms'
          }}>
          <div
            style={{
              width: '100%',
              height: '100%',
              position: 'absolute',
              zIndex: 100
            }}>
            <div
              style={{
                position: 'relative',
                top: -8,
                right: -7,
                zIndex: 100,
                display: 'flex',
                justifyContent: 'flex-end'
              }}>
              {url === selectedImage ? (
                <svg version="1.1" width="15" height="15" viewBox="0 0 508 508">
                  <g>
                    <g>
                      <path
                        d="m508 254c0 16.23-8.43 29.71-15.87 41.59-4.4 7.04-8.56 13.68-10.12 19.5-1.67 6.27-1.41 14.38-1.13 22.97.45 13.79.96 29.42-6.89 42.97-7.95 13.75-21.85 21.14-34.11 27.66-7.49 3.98-14.56 7.74-19 12.19-4.45 4.44-8.21 11.51-12.19 19-6.52 12.26-13.91 26.16-27.66 34.12-13.55 7.84-29.18 7.33-42.97 6.88-8.59-.28-16.7-.54-22.97 1.13-5.81 1.56-12.46 5.72-19.5 10.12-11.88 7.44-25.36 15.87-41.59 15.87h-.11l-35.355-262.45 35.355-245.55h.11c16.23 0 29.71 8.43 41.59 15.87 7.04 4.4 13.69 8.56 19.5 10.12 6.27 1.67 14.38 1.41 22.97 1.13 13.79-.45 29.42-.96 42.97 6.88 13.75 7.96 21.14 21.86 27.66 34.12 3.98 7.49 7.74 14.56 12.19 19 4.44 4.45 11.51 8.21 19 12.19 12.26 6.52 26.16 13.91 34.12 27.66 7.84 13.55 7.33 29.18 6.88 42.97-.28 8.59-.54 16.7 1.13 22.97 1.56 5.81 5.72 12.46 10.12 19.49 7.44 11.89 15.87 25.37 15.87 41.6z"
                        fill="#7da7ff"
                        data-original="#00cb76"
                      />
                      <path
                        d="m253.89 0v508c-16.18-.04-29.63-8.45-41.48-15.87-7.04-4.4-13.69-8.56-19.5-10.12-6.27-1.67-14.38-1.41-22.97-1.13-13.79.45-29.42.96-42.97-6.88-13.75-7.96-21.14-21.86-27.66-34.12-3.98-7.49-7.74-14.56-12.19-19-4.44-4.45-11.51-8.21-19-12.19-12.26-6.52-26.16-13.91-34.12-27.66-7.84-13.55-7.33-29.18-6.88-42.97.28-8.59.54-16.7-1.13-22.97-1.56-5.81-5.72-12.46-10.12-19.49-7.44-11.89-15.87-25.37-15.87-41.6s8.43-29.71 15.87-41.59c4.4-7.04 8.56-13.68 10.12-19.5 1.67-6.27 1.41-14.38 1.13-22.97-.45-13.79-.96-29.42 6.89-42.97 7.95-13.75 21.85-21.14 34.11-27.66 7.49-3.98 14.56-7.74 19-12.19 4.45-4.44 8.21-11.51 12.19-19 6.52-12.26 13.91-26.16 27.66-34.12 13.55-7.84 29.18-7.33 42.97-6.88 8.59.28 16.7.54 22.97-1.13 5.81-1.56 12.46-5.72 19.5-10.12 11.85-7.42 25.3-15.83 41.48-15.87z"
                        fill="#7da7ff"
                        data-original="#a4e786"
                      />
                      <path
                        d="m445.08 254c0 105.36-85.72 191.08-191.08 191.08h-.11l-7.39-10.724 7.39-19.276h.11c88.82 0 161.08-72.26 161.08-161.08s-72.26-161.08-161.08-161.08h-.11l-12.543-12.148 12.543-17.852h.11c105.36 0 191.08 85.72 191.08 191.08z"
                        fill="#ffffff"
                        data-original="#009a85"
                      />
                      <path
                        d="m344.39 212.93-90.5 86.88-7.39-15.81 7.39-25.78 69.72-66.93z"
                        fill="#ffffff"
                        data-original="#009a85"
                      />
                      <g fill="#00cb76">
                        <path
                          d="m253.89 258.22v41.59l-28.21 27.08-62.29-62.28 21.22-21.22 41.5 41.5z"
                          fill="#ffffff"
                          data-original="#00cb76"
                        />
                        <path
                          d="m253.89 92.92v-30c-105.31.06-190.97 85.76-190.97 191.08s85.66 191.02 190.97 191.08v-30c-88.77-.06-160.97-72.3-160.97-161.08s72.2-161.02 160.97-161.08z"
                          fill="#ffffff"
                          data-original="#00cb76"
                        />
                      </g>
                    </g>
                  </g>
                </svg>
              ) : null}
            </div>
          </div>

          <img
            style={{
              backgroundRepeat: 'no-repeat',
              width: '100%',
              height: 110,
              objectFit: 'cover'
            }}
            alt={image.url}
            src={image.url}
          />
        </div>
      );
    });
    setCopyData(copy2);
    setClientImages(
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          flexWrap: 'wrap',
          flexFlow: 'row wrap',
          alignContent: 'flex-end',
          justifyContent: 'space-between'
        }}>
        {imgs}
      </div>
    );
    return;
  };
  useEffect(() => {
    getImages();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedImage]);

  // ============================================
  const setLong = ({ gmb: gmbLink, yelp: yelpLink }) => {
    setLongGmb(gmbLink);
    setLongYelp(yelpLink);
  };

  // ================ GET REBRANDLY ====================

  const generateShortLink = async (url, link) => {
    let payload = {
      description: clientInfo.clientName,
      destination: url,
      domain: { fullName: link }
    };
    let axiosConfig = {
      headers: {
        'Content-Type': 'application/json',
        apikey: REBRANDLY_APIKEY
        // workspace: REBRANDLY_WORKSPACE,
      }
    };

    let res = await axios
      .post('https://api.rebrandly.com/v1/links', payload, axiosConfig)
      .then((response) => {
        if (link === 'google-review.link') {
          return response.data;
        } else {
          return response.data;
        }
      })
      .catch((error) => {
        console.error(error);
      });
    return res;
  };

  // ==================== Check format URL ============================

  const createShortLinks = async () => {
    const urlRegexp =
      /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)?/gi;
    const regex = new RegExp(urlRegexp);
    let gmbShort = '';
    let yelpShort = '';
    let data = '';
    let gmbId = '';
    let yelpId = '';

    if (gmbLink.long) {
      if (gmbLink.long.match(regex)) {
        if (longGmb !== gmbLink.long) {
          const reviewLink = 'google-review.link';

          data = await generateShortLink(gmbLink.long, reviewLink);
          gmbShort = data.shortUrl;
          gmbId = data.id;
          setGmbLink({ ...gmbLink, short: data.shortUrl });
        } else {
          gmbShort = gmbLink.short;
          setGmbLink({ ...gmbLink, short: gmbShort });
          gmbId = gmbShortId;
        }
      } else {
        Swal.fire('Oops!', '<span> Invalid gmb link </span>');
        setSavingClient(false);
        return;
      }
    }
    let formatedLink = null;
    if (yelpLink.long) {
      if (yelpLink.long.match(regex)) {
        if (longYelp !== yelpLink.long) {
          const reviewLink = 'yelp-review.link';
          formatedLink = yelpLink.long?.includes('&rating=5')
            ? yelpLink.long
            : yelpLink.long + '&rating=5';
          data = await generateShortLink(formatedLink, reviewLink);
          yelpShort = data.shortUrl;
          yelpId = data.id;
          setYelpLink({
            short: data.shortUrl,
            long: formatedLink
          });
          setLongYelp(formatedLink);
        } else {
          yelpShort = yelpLink.short;
          setYelpLink({ ...yelpLink, short: yelpShort });
          yelpId = yelpShortId;
        }
      } else {
        Swal.fire('Oops!', '<span> Invalid yelp link. </span>');
        setSavingClient(false);
        return;
      }
    }
    saveClient(gmbShort, yelpShort, gmbId, yelpId, formatedLink);
  };

  // =================== SAVE CLIENT ====================

  const saveClient = async (
    gmbShort,
    yelpShort,
    gmbId,
    yelpId,
    yelpLongLink
  ) => {
    let axiosConfig = {
      headers: {
        'Content-Type': 'application/json'
      }
    };

    let payload = {
      email,
      password,
      message,
      id: clientInfo.id,
      company: clientInfo.clientName,
      gmbLongLink: gmbLink.long,
      gmbShortLink: gmbShort,
      yelpLongLink: yelpLongLink || yelpLink.long,
      yelpShortLink: yelpShort,
      gmbId,
      yelpId,
      featureFlags,
      selectedImage
    };
    // selectedImage <--- This will be in the payload to save images

    axios
      .post(APP_REGISTER, payload, axiosConfig)
      .then((response) => {
        const { statusCode, body } = response.data;
        if (statusCode === 201) {
          Swal.fire('Error!', '<span>' + body + '</span>');
          setSavingClient(false);
          return;
        } else if (statusCode === 200) {
          setSavingClient(false);
          Swal.fire('Success!', `<span> ${body} </span>`).then(() => {
            setPassword('');
            setPassword2('');
            setEmail('');
            props.onUpdate();
            props.handleClose();
            if (body.includes('created')) {
              window.location.reload();
            }
          });
        } else {
          Swal.fire('Error!', '<span>' + body + '</span>');
          setSavingClient(false);
        }
      })
      .catch((error) => {
        console.error('error', error);
      });
  };

  const verifyForm = async (email, password, password2) => {
    function ValidateEmail(inputText) {
      let mailformat =
        // eslint-disable-next-line no-control-regex
        /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/;
      const regex = new RegExp(mailformat);
      if (inputText.match(regex)) {
        return true;
      } else {
        return false;
      }
    }
    if (!loadingData) {
      setSavingClient(true);
      if (email.length >= 6 && ValidateEmail(email)) {
        setEmailStatus('form-control');
        if (password.length >= 8) {
          if (password === password2) {
            if (message.length >= 10) {
              if (gmbLink.long || yelpLink.long) {
                createShortLinks();
              } else {
                Swal.fire(
                  'Oops!',
                  '<span> Please include either a GMB or a Yelp link. </span>'
                ).then(() => {
                  setSavingClient(false);
                });
              }
            } else {
              Swal.fire(
                'Oops!',
                '<span> The message must containe at least 10 characters. </span>'
              ).then(() => {
                setSavingClient(false);
                setMessageStatus('form-control error-field');
              });
            }
          } else {
            Swal.fire('Ups!', "<span> Passwords don't match </span>").then(
              () => {
                setSavingClient(false);
                setPasswordStatus('form-control error-field');
              }
            );
          }
        } else {
          setSavingClient(false);
          Swal.fire(
            'Ups!',
            '<span> Your password must contain at least 8 characters </span>'
          ).then(() => {
            setPasswordStatus('form-control error-field');
          });
        }
      } else {
        Swal.fire('Ups!', '<span> Check the <b>email</b> address.</span>').then(
          () => {
            setEmailStatus('form-control error-field');
          }
        );
        setSavingClient(false);
      }
    }
  };
  const handleChangePass = (event) => {
    setPassword(event.target.value);
    setPasswordStatus('form-control');
  };
  const handleChangePass2 = (event) => {
    setPassword2(event.target.value);
    setPasswordStatus('form-control');
  };
  const handleChangeEmail = (event) => {
    setEmail(event.target.value);
    setEmailStatus('form-control');
  };
  const handleChangeMessage = (event) => {
    setMessage(event.target.value);
    setMessageStatus('form-control');
  };
  const changeTypePass = () => {
    if (typePass === 'password') {
      setTypePass('text');
    } else {
      setTypePass('password');
    }
  };
  // const handleUpload = async (file) => {
  //   const toBase64 = (file) =>
  //     new Promise((resolve, reject) => {
  //       const reader = new FileReader();
  //       reader.readAsDataURL(file);
  //       reader.onload = () => resolve(reader.result.split(',')[1]);
  //       reader.onerror = (error) => reject(error);
  //     });

  //   try {
  //     const fileContent = await toBase64(file);

  //     const response = await fetch(API_UPLOAD_S3, {
  //       method: 'POST',
  //       headers: {
  //         'Content-Type': 'application/json'
  //       },
  //       body: JSON.stringify({
  //         fileName: file.name,
  //         fileContent,
  //         bucketName: AWS_BUCKET_MMS
  //       })
  //     });

  //     if (!response.ok) {
  //       throw new Error('Error uploading file');
  //     }

  //     const data = await response.json();
  //     console.log('File uploaded successfully:', data.url);
  //   } catch (error) {
  //     console.error('Upload failed:', error);
  //   }
  // };

  const handleUpload = async (file) => {
    let newFileName = uuid();
    const ReactS3Client = new S3(config);
    await ReactS3Client.uploadFile(file, newFileName);
    return;
  };

  return (
    <React.Fragment>
      <div className="modal-backdrop in"></div>
      <div
        className="modal fade in show"
        id="exampleModal"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
        style={{ display: 'block' }}>
        <div
          className="modal-dialog modal-dialog-centered modal-xl "
          role="document">
          <div className="modal-content tx-12">
            <div className="modal-header">
              <h5 className="modal-title" id="addNewGroupLabel">
                {clientInfo.clientName}
                {loadingData && (
                  <div
                    className="spinner-border spinner-border-sm"
                    role="status">
                    <span className="sr-only">Loading...</span>
                  </div>
                )}
              </h5>
              <button
                type="button"
                className="btn-close"
                data-dismiss="modal"
                aria-label="Close"
                onClick={() => props.handleClose()}
                disabled={savingClient || uploadingImages}></button>
            </div>
            <form>
              <div className="modal-body">
                <div className="row">
                  <div className="col-lg-5">
                    <h4>{'Account Information'}</h4>
                    <div
                      className="card"
                      style={{ maxHeight: '600px', minHeight: '325px' }}>
                      <div className="card-body">
                        <h6 className="card-title">Client's Email</h6>
                        <input
                          className={emailStatus} //"form-control error-field"
                          type="text"
                          rows="1"
                          style={{ marginBottom: 8 }}
                          onChange={handleChangeEmail}
                          value={email}
                          disabled={loadingData}
                        />
                        <div
                          style={{
                            display: 'flex',
                            justifyContent: 'space-between'
                          }}>
                          <div style={{ width: '45%' }}>
                            <h6 className="card-title">Password</h6>
                          </div>
                          <div style={{ width: '45%' }}>
                            <h6 className="card-title">Repeat Password</h6>
                          </div>
                        </div>

                        <div
                          style={{
                            display: 'flex',
                            justifyContent: 'space-between'
                          }}>
                          <div style={{ display: 'flex', width: '45%' }}>
                            <input
                              className={passwordStatus}
                              type={typePass}
                              rows="1"
                              style={{ marginBottom: 8 }}
                              onChange={handleChangePass}
                              value={password}
                              disabled={loadingData}
                            />

                            <button
                              style={{
                                border: 0,
                                backgroundColor: 'transparent'
                              }}
                              onClick={() => {
                                changeTypePass();
                              }}
                              type="button"
                              className="btn btn-outline-secondary btn-sm mb-2">
                              {typePass !== 'text' ? (
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="24"
                                  height="24"
                                  fill="gray"
                                  viewBox="0 0 24 24">
                                  <path d="M15 12c0 1.654-1.346 3-3 3s-3-1.346-3-3 1.346-3 3-3 3 1.346 3 3zm9-.449s-4.252 8.449-11.985 8.449c-7.18 0-12.015-8.449-12.015-8.449s4.446-7.551 12.015-7.551c7.694 0 11.985 7.551 11.985 7.551zm-7 .449c0-2.757-2.243-5-5-5s-5 2.243-5 5 2.243 5 5 5 5-2.243 5-5z" />
                                </svg>
                              ) : (
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="24"
                                  height="24"
                                  fill="gray"
                                  viewBox="0 0 24 24">
                                  <path d="M11.885 14.988l3.104-3.098.011.11c0 1.654-1.346 3-3 3l-.115-.012zm8.048-8.032l-3.274 3.268c.212.554.341 1.149.341 1.776 0 2.757-2.243 5-5 5-.631 0-1.229-.13-1.785-.344l-2.377 2.372c1.276.588 2.671.972 4.177.972 7.733 0 11.985-8.449 11.985-8.449s-1.415-2.478-4.067-4.595zm1.431-3.536l-18.619 18.58-1.382-1.422 3.455-3.447c-3.022-2.45-4.818-5.58-4.818-5.58s4.446-7.551 12.015-7.551c1.825 0 3.456.426 4.886 1.075l3.081-3.075 1.382 1.42zm-13.751 10.922l1.519-1.515c-.077-.264-.132-.538-.132-.827 0-1.654 1.346-3 3-3 .291 0 .567.055.833.134l1.518-1.515c-.704-.382-1.496-.619-2.351-.619-2.757 0-5 2.243-5 5 0 .852.235 1.641.613 2.342z" />
                                </svg>
                              )}
                            </button>
                          </div>
                          <div style={{ display: 'flex', width: '45%' }}>
                            <input
                              className={passwordStatus}
                              type={typePass}
                              rows="1"
                              style={{ marginBottom: 8 }}
                              onChange={handleChangePass2}
                              value={password2}
                              disabled={loadingData}
                            />
                            <button
                              onClick={() => {
                                changeTypePass();
                              }}
                              style={{
                                border: 0,
                                backgroundColor: 'transparent'
                              }}
                              disabled={loadingData}
                              type="button"
                              className="btn btn-outline-secondary btn-sm mb-2">
                              {typePass !== 'text' ? (
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="24"
                                  height="24"
                                  fill="gray"
                                  viewBox="0 0 24 24">
                                  <path d="M15 12c0 1.654-1.346 3-3 3s-3-1.346-3-3 1.346-3 3-3 3 1.346 3 3zm9-.449s-4.252 8.449-11.985 8.449c-7.18 0-12.015-8.449-12.015-8.449s4.446-7.551 12.015-7.551c7.694 0 11.985 7.551 11.985 7.551zm-7 .449c0-2.757-2.243-5-5-5s-5 2.243-5 5 2.243 5 5 5 5-2.243 5-5z" />
                                </svg>
                              ) : (
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="24"
                                  height="24"
                                  fill="gray"
                                  viewBox="0 0 24 24">
                                  <path d="M11.885 14.988l3.104-3.098.011.11c0 1.654-1.346 3-3 3l-.115-.012zm8.048-8.032l-3.274 3.268c.212.554.341 1.149.341 1.776 0 2.757-2.243 5-5 5-.631 0-1.229-.13-1.785-.344l-2.377 2.372c1.276.588 2.671.972 4.177.972 7.733 0 11.985-8.449 11.985-8.449s-1.415-2.478-4.067-4.595zm1.431-3.536l-18.619 18.58-1.382-1.422 3.455-3.447c-3.022-2.45-4.818-5.58-4.818-5.58s4.446-7.551 12.015-7.551c1.825 0 3.456.426 4.886 1.075l3.081-3.075 1.382 1.42zm-13.751 10.922l1.519-1.515c-.077-.264-.132-.538-.132-.827 0-1.654 1.346-3 3-3 .291 0 .567.055.833.134l1.518-1.515c-.704-.382-1.496-.619-2.351-.619-2.757 0-5 2.243-5 5 0 .852.235 1.641.613 2.342z" />
                                </svg>
                              )}
                            </button>
                          </div>
                        </div>
                        <hr></hr>
                        <h6 className="card-title">Google Link</h6>
                        <div className="d-flex justify-content-between align-items-center mb-2">
                          <input
                            className="form-control"
                            style={{ width: '90%' }}
                            type="text"
                            value={gmbLink.long}
                            onChange={(e) =>
                              setGmbLink({ ...gmbLink, long: e.target.value })
                            }
                            disabled={loadingData}
                          />
                          <svg
                            stroke="currentColor"
                            fill="currentColor"
                            strokeWidth="0"
                            viewBox="0 0 512 512"
                            height="1em"
                            width="1em"
                            xmlns="http://www.w3.org/2000/svg"
                            className={
                              gmbLink?.short?.length > 0
                                ? 'text-success'
                                : 'text-danger'
                            }>
                            <path d="M256 8C119.033 8 8 119.033 8 256s111.033 248 248 248 248-111.033 248-248S392.967 8 256 8zm80 248c0 44.112-35.888 80-80 80s-80-35.888-80-80 35.888-80 80-80 80 35.888 80 80z"></path>
                          </svg>
                        </div>

                        <h6 className="card-title">Yelp Link</h6>
                        <div className="d-flex justify-content-between align-items-center mb-2">
                          <input
                            className="form-control"
                            style={{ width: '90%' }}
                            type="text"
                            value={yelpLink.long}
                            autoComplete="nope"
                            onChange={(e) =>
                              setYelpLink({ ...yelpLink, long: e.target.value })
                            }
                            disabled={loadingData}
                          />
                          <svg
                            stroke="currentColor"
                            fill="currentColor"
                            strokeWidth="0"
                            viewBox="0 0 512 512"
                            height="1em"
                            width="1em"
                            xmlns="http://www.w3.org/2000/svg"
                            className={
                              yelpLink?.short?.length > 0
                                ? 'text-success'
                                : 'text-danger'
                            }>
                            <path d="M256 8C119.033 8 8 119.033 8 256s111.033 248 248 248 248-111.033 248-248S392.967 8 256 8zm80 248c0 44.112-35.888 80-80 80s-80-35.888-80-80 35.888-80 80-80 80 35.888 80 80z"></path>
                          </svg>
                        </div>
                        <div
                          style={{
                            marginTop: 10,
                            display: 'flex',
                            justifyContent: 'space-between'
                          }}>
                          <div style={{ width: '45%' }}>
                            <h6 className="card-title">Short Link GMB</h6>
                          </div>
                          <div style={{ width: '45%' }}>
                            <h6 className="card-title">Short Link Yelp</h6>
                          </div>
                        </div>
                        <div
                          style={{
                            display: 'flex',
                            justifyContent: 'space-between'
                          }}>
                          <div style={{ width: '45%' }}>
                            <input
                              className="form-control"
                              type="text"
                              rows="1"
                              style={{ marginBottom: 8 }}
                              value={gmbLink.short}
                              disabled
                            />
                          </div>
                          <div style={{ width: '45%' }}>
                            <input
                              className="form-control"
                              type="text"
                              rows="1"
                              style={{ marginBottom: 8 }}
                              value={yelpLink.short}
                              disabled
                            />
                          </div>
                        </div>
                        <hr />
                        <div
                          style={{
                            marginTop: 10,
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'space-between'
                          }}>
                          <div style={{ width: '45%' }}>
                            <h6 className="card-title">Feature Flags</h6>
                          </div>
                          <div style={{ width: '45%' }}>
                            <div
                              style={{
                                display: 'flex',
                                flexDirection: 'row'
                              }}>
                              <p
                                style={{
                                  fontSize: '13px'
                                }}>
                                Notifications
                              </p>
                              <Switch
                                size="small"
                                checked={featureFlags?.notifications}
                                disabled={loadingData}
                                color={'primary'}
                                onChange={() => {
                                  setFeatureFlags({
                                    ...featureFlags,
                                    notifications:
                                      !(featureFlags?.hasOwnProperty(
                                        'notifications'
                                      )
                                        ? featureFlags.notifications
                                        : false)
                                  });
                                }}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-lg-7">
                    <h4>{'Message Configuration'}</h4>
                    <div
                      className="card"
                      style={{
                        flex: 1,
                        display: 'flex',
                        maxHeight: '500px',
                        minHeight: '325px',

                        alignItems: 'center'
                      }}>
                      <div className="card-body">
                        <div
                          style={{
                            display: 'flex',
                            flexDirection: 'row',

                            justifyContent: 'space-evenly'
                          }}>
                          <div
                            style={{
                              display: 'flex',
                              flexDirection: 'row',
                              width: '280px',

                              justifyContent: 'center'
                            }}>
                            <Mobile
                              isLoading={loadingData}
                              message={message}
                              clientName={clientInfo.clientName}
                              image={selectedImage}
                              yelpLink={yelpLink.short}
                              gmbLink={gmbLink.short}
                            />
                          </div>
                          <div
                            style={{
                              width: '45%',
                              display: 'flex',
                              flexDirection: 'column'
                            }}>
                            <h6 style={{ marginTop: 5 }} className="card-title">
                              Custom Message
                            </h6>
                            <textarea
                              className={`${messageStatus} custom-sms`}
                              rows="2"
                              style={{
                                minWidth: '270px',
                                minHeight: '80px',
                                resize: 'none'
                              }}
                              onChange={handleChangeMessage}
                              value={message}
                              disabled={loadingData}
                            />

                            <div>
                              <h6
                                style={{ marginTop: 5 }}
                                className="card-title">
                                MMS (image to send)
                              </h6>
                            </div>
                            {loadingData ? (
                              <div
                                className="card"
                                style={{
                                  textAlign: 'center',
                                  justifyItems: 'center',
                                  alignItems: 'center',
                                  justifyContent: 'center',
                                  maxHeight: '250px',
                                  minHeight: '250px',
                                  width: '272px'
                                }}>
                                loading...
                              </div>
                            ) : clientImages ? (
                              <div
                                className="card"
                                style={{
                                  maxHeight: '250px',
                                  minHeight: '250px',
                                  overflow: 'scroll',
                                  width: '272px'
                                }}>
                                {clientImages !== null ? clientImages : null}
                              </div>
                            ) : (
                              /* HERE WE NEED TO ADD THE VARIABLE THAT SAYS IF THE USER HAS STORED IMAGES */
                              <div
                                className="card"
                                style={{
                                  textAlign: 'center',
                                  justifyItems: 'center',
                                  alignItems: 'center',
                                  justifyContent: 'center',
                                  maxHeight: '250px',
                                  minHeight: '250px',
                                  width: '272px'
                                }}>
                                No images
                              </div>
                            )}
                            <div
                              style={{
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'space-between'
                              }}>
                              <input
                                style={{
                                  visibility: 'hidden',
                                  position: 'absolute'
                                }}
                                id="image_uploads"
                                onChange={(e) => {
                                  setFiles(e.target.files);

                                  console.log(e.target.files.length);
                                  console.log(e.target.files);
                                }}
                                type="file"
                                // ref={(im) => {
                                //   console.log('TESTING... =>', im);
                                // }}
                                multiple
                                disabled={uploadingImages}
                                // uploadingImages <---- This will be in the disabled prop input
                                accept="image/png, image/jpeg"
                              />
                            </div>
                            <label
                              style={{ width: '270px' }}
                              className={
                                savingClient || uploadingImages || loadingData
                                  ? 'btn  btn-outline-light active'
                                  : 'btn btn-outline-primary'
                              }
                              htmlFor="image_uploads">
                              Add Images
                            </label>
                            <span style={{ color: 'red' }}>
                              Only .jpg/.png images will be accepted
                            </span>
                          </div>
                        </div>

                        {/* HERE WILL BE THE ADDING IMGS IMPLEMENTATION */}
                        {files.length > 0 ? (
                          <div
                            className="form-group"
                            style={{
                              display: 'flex',
                              justifyContent: 'center'
                            }}>
                            <React.Fragment>
                              <div className="modal-backdrop in"></div>
                              <div
                                className="modal fade in show"
                                id="exampleModal"
                                tabIndex="-1"
                                role="dialog"
                                aria-labelledby="exampleModalLabel"
                                aria-hidden="true"
                                style={{ display: 'block' }}>
                                <div
                                  className="modal-dialog modal-dialog-centered modal-lg tx-2"
                                  role="document">
                                  <div className="modal-content tx-12">
                                    <div className="modal-header">
                                      <h5
                                        className="modal-title"
                                        id="addNewGroupLabel">
                                        Upload Images ({files.length})
                                      </h5>
                                      <button
                                        type="button"
                                        className="btn-close"
                                        data-dismiss="modal"
                                        aria-label="Close"
                                        onClick={() => setFiles({ length: 0 })}
                                        disabled={
                                          savingClient || uploadingImages
                                        }></button>
                                    </div>
                                    <div className="modal-body">
                                      <div
                                        className="card"
                                        style={{
                                          maxHeight: '500px',
                                          minHeight: '325px',
                                          overflow: 'scroll'
                                        }}>
                                        <div className="card-body">
                                          <div>{renderImages(files)}</div>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="modal-footer">
                                      <button
                                        type="button"
                                        disabled={
                                          savingClient || uploadingImages
                                        }
                                        className="btn btn-danger tx-13"
                                        data-dismiss="modal"
                                        onClick={() => setFiles({ length: 0 })}>
                                        Cancel
                                      </button>
                                      <button
                                        type="button"
                                        disabled={
                                          savingClient ||
                                          uploadingImages ||
                                          files.length === 0
                                        }
                                        className="btn btn-primary"
                                        onClick={async () => {
                                          setUploadingImages(true);
                                          setProgress(0);

                                          for (
                                            let i = 0;
                                            i < files.length;
                                            i++
                                          ) {
                                            await handleUpload(files[i]);

                                            const progressPercentage =
                                              Math.round(
                                                ((i + 1) / files.length) * 100
                                              );
                                            setProgress(progressPercentage);
                                          }

                                          setTimeout(async () => {
                                            setUploadingImages(false);
                                            setFiles({ length: 0 });
                                            setProgress(0);
                                            setCopyData(null);
                                            getImages(true);
                                          }, 500);
                                        }}>
                                        <span
                                          className={`spinner-border spinner-border-sm mr-1 ${
                                            !uploadingImages ? 'hidden' : ''
                                          }`}
                                          role="status"
                                          aria-hidden="true"></span>
                                        {uploadingImages
                                          ? `Uploading... ${
                                              progress !== 0 ? progress : '0'
                                            }%`
                                          : 'Upload'}
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </React.Fragment>
                          </div>
                        ) : null}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  disabled={savingClient || uploadingImages}
                  className="btn btn-secondary tx-13"
                  data-dismiss="modal"
                  onClick={() => props.handleClose()}>
                  Close
                </button>
                <ButtonLoad
                  state={savingClient}
                  disabled={savingClient || uploadingImages}
                  btn={'btn-primary btn-success'}
                  icon={'fa-check-square'}
                  text={' Confirm'}
                  callback={() => {
                    verifyForm(email, password, password2);
                  }}
                />
              </div>
            </form>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default AppRegister;
