import React from 'react';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import TabPanel from './TabPanel';

function a11yProps(index) {
  return {
    id: `scrollable-auto-tab-${index}`,
    'aria-controls': `scrollable-auto-tabpanel-${index}`
  };
}

const titlesNames = [
  'App Gallery',
  'Content Gallery',
  'Pixabay Gallery',
  'Pexels Gallery',
  'Unsplash Gallery'
];

const TabMenu = (props) => {
  const { children, state } = props;
  const { value, handleIndexChange } = state;

  return (
    <>
      <AppBar position="static" color="default" style={{ boxShadow: 'none' }}>
        <Tabs
          value={value}
          onChange={handleIndexChange}
          indicatorColor="primary"
          textColor="primary"
          variant="scrollable"
          aria-label="scrollable auto tabs example">
          {children.map((element, index) => {
            return (
              <Tab
                key={index}
                label={titlesNames[index]}
                {...a11yProps(index)}
              />
            );
          })}
        </Tabs>
      </AppBar>
      {children.map((element, index) => {
        return (
          <TabPanel key={index} value={value} index={index}>
            {element}
          </TabPanel>
        );
      })}
    </>
  );
};

export default TabMenu;
