import React, { createRef, useEffect, useState, forwardRef } from 'react';
import Select, { components } from 'react-select';
import { GET_ZOHO_ACCOUNTS, UPDATE_ZOHO_ACCOUNT } from '../utils/constants';
import Swal from 'sweetalert2';
import ButtonLoad from './ButtonLoad';

const axios = require('axios');
const { Option } = components;
const CustomSelectOption = (props) => (
  <Option {...props}>
    <span
      style={{ color: props.data.icon === 'unlink' ? 'firebrick' : 'green' }}>
      <i className={`fas fa-${props.data.icon}`} />
    </span>
    &nbsp;&nbsp;
    {props.data.label}
  </Option>
);

const NewZohoConnection = forwardRef((props, ref) => {
  const [optionsData, setOptionsData] = useState(null);
  const [loaded, setLoaded] = useState(false);
  const [zohoAccount, setZohoAccount] = useState(null);
  const [currentClient, setCurrentClient] = useState(null);
  const [savingClient, setSavingClient] = useState(false);
  const [modalDismiss, setModalDismiss] = useState(createRef());

  useEffect(() => {
    const getPages = async () => {
      const res = await axios.get(GET_ZOHO_ACCOUNTS).catch((error) => {
        console.error('error ' + error);
        throw new Error(error);
      });

      if (optionsData === null) {
        setOptionsData(res.data.body);
        setLoaded(true);
      }
    };
    if (optionsData === null) {
      getPages();
    }
  }, [optionsData]);

  const handleSelectChanges = (option) => {
    if (option) {
      const Options = {
        id: option.value,
        Account_Name: option.label,
        currentClientId: props.currentClientId
      };

      setZohoAccount(Options);
      setCurrentClient(props.currentClientId);
    }
  };

  const zohoConnectionHandler = () => {
    setSavingClient(true);
    if (zohoAccount == null) {
      Swal.fire({
        title: 'Error!',
        text: 'Please select an account.'
      }).then((r) => {
        setSavingClient(false);
        setZohoAccount(null);
      });
      return;
    }

    axios({
      method: 'post',
      url: UPDATE_ZOHO_ACCOUNT,
      data: zohoAccount,
      headers: { 'Content-Type': 'application/json' }
    })
      .then(function (response) {
        if (response.status === 200) {
          setSavingClient(false);
          setOptionsData(null);
          setZohoAccount(null);
          modalDismiss.click();
          someFn(currentClient);
        }
      })
      .catch(function (response) {
        setOptionsData(null);
        setZohoAccount(null);
        setSavingClient(false);
      });
  };

  const someFn = (dataForParent) => {
    props.connectSnCallback(dataForParent);
  };

  if (!loaded) {
    return '';
  }

  return (
    <div
      className="modal fade show"
      id="newZohoConnection"
      tabIndex={-1}
      role="dialog"
      ref={ref}>
      <div className="modal-dialog modal-dialog-centered" role="document">
        <div className="modal-content tx-14">
          <div className="modal-header">
            <h6 className="modal-title" id="addNewClientLabel">
              Connect Client Zoho Account
            </h6>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
              onClick={() => {
                setOptionsData(null);
                setZohoAccount(null);
              }}></button>
          </div>
          <div className="modal-body">
            <form>
              <div className="form-group">
                <div className={'content-title-entitys'}>
                  <label htmlFor="formGroupExampleInput" className="d-block">
                    What's the client zoho account?
                  </label>
                  <span className={'counter-entity'}>
                    {optionsData?.length}
                  </span>
                </div>
                <Select
                  options={optionsData?.map((option, idx) => ({
                    value: option.id,
                    label: option.Account_Name,
                    icon: option?.assignedClient ? 'link' : 'unlink'
                  }))}
                  components={{ Option: CustomSelectOption }}
                  onChange={handleSelectChanges}
                  isSearchable
                  isClearable
                />
              </div>
            </form>
          </div>

          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-secondary tx-13"
              data-bs-dismiss="modal"
              onClick={() => {
                setOptionsData(null);
                setZohoAccount(null);
              }}
              ref={(button) => {
                setModalDismiss(button);
              }}>
              Close
            </button>
            <ButtonLoad
              state={savingClient}
              btn={'btn-primary'}
              icon={'fa-save'}
              text={' Save Client'}
              callback={() => {
                zohoConnectionHandler();
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
});

export default NewZohoConnection;
