import React, { useEffect, useState, createRef, forwardRef } from 'react';
import Select, { components } from 'react-select';
import Swal from 'sweetalert2';
import ButtonLoad from './ButtonLoad';
import AWS_Lambda from '../aws/aws';
import axios from 'axios';
import { API_RESOLVE_FAILING_SN } from '../utils/constants';
import { useSelector } from 'react-redux';
import { authSelector } from '../store/slices/auth';

const { Option } = components;
const CustomSelectOption = (props) => (
  <Option {...props}>
    <span
      style={{ color: props.data.icon === 'times' ? 'firebrick' : 'green' }}>
      <i className={`fas fa-${props.data.icon}-circle`} />
    </span>
    &nbsp;
    {props.data.label}
  </Option>
);

const NewInstagramConnection = forwardRef((props, ref) => {
  const { isAuthenticated, userInfo } = useSelector(authSelector);
  const [modalDismiss, setModalDismiss] = useState(createRef());
  const [state, setState] = useState({
    optionsData: null,
    loaded: false,
    instagramPage: null,
    currentClient: null,
    savingClient: false
  });
  const [isFailing, setIsFailing] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const handleResolved = async (snName) => {
    let config = {
      method: 'post',
      url: API_RESOLVE_FAILING_SN,
      data: { clientId: props.currentClientId, snName },
      headers: { 'Content-Type': 'application/json' }
    };

    if (isFailing) {
      setIsLoading(true);
      await axios(config)
        .then(async (response) => {
          if (response.status === 200) {
            setIsFailing(false);
            setIsLoading(false);
            await someFn();
          }
        })
        .catch((error) => {
          console.error(error);
          setIsLoading(false);
        });
    }
  };
  useEffect(() => {
    if (props?.currentClient?.clientSocialNetworks?.igFailing !== undefined) {
      setIsFailing(props?.currentClient?.clientSocialNetworks?.igFailing);
    } else {
      setIsFailing(false);
    }
  }, [props.currentClient, props.currentClientId]);

  useEffect(() => {
    async function getPages() {
      let params = {
        FunctionName: process.env.REACT_APP_GET_INSTAGRAM_LAMBDA
      };

      AWS_Lambda.invoke(params)
        .promise()
        .then((response) => {
          let JResponse = JSON.parse(response.Payload);

          const JPages = JSON.parse(JResponse.body);

          setState({ ...state, optionsData: JPages.IGpages, loaded: true });
        })
        .catch((error) => {
          console.error('error ' + error);
        });
    }
    if (state.optionsData === null) getPages();
  }, [props.currentClientId, state.optionsData, state]);

  const handleSelectChanges = (option) => {
    if (option) {
      const options = { id: option.value, entity_name: option.label };

      setState({
        ...state,
        instagramPage: options,
        currentClient: props.currentClientId
      });
    }
  };

  const instagramPageConnectionHandler = () => {
    setState({ ...state, savingClient: true });
    if (state.instagramPage == null) {
      Swal.fire({
        title: 'Error!',
        text: 'Please select a page.'
      }).then((r) => {
        setState({ ...state, savingClient: false });
      });
      return;
    }

    let IGparams = {
      FunctionName: process.env.REACT_APP_PASSING_INSTAGRAM_LAMBDA,
      Payload: JSON.stringify({
        body: JSON.stringify({
          clientId: state.currentClient,
          instagramPageId: state.instagramPage.id,
          instagramPageName: state.instagramPage.entity_name
        })
      })
    };

    AWS_Lambda.invoke(IGparams)
      .promise()
      .then(function () {
        setState({ ...state, savingClient: false });
        modalDismiss.click();
        someFn(state.currentClient);
      })
      .catch(function () {
        setState({ ...state, savingClient: false });
      });
  };

  const someFn = async () => {
    await props.connectSnCallback();
  };

  if (!state.loaded && !state.optionsData) {
    return '';
  }

  return (
    <div>
      <div
        className="modal fade"
        id="newInstagramConnection"
        tabIndex={-1}
        role="dialog"
        aria-labelledby="addNewClientLabel"
        aria-hidden="true"
        ref={ref}>
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content tx-14">
            <div className="modal-header">
              <h6 className="modal-title" id="addNewClientLabel">
                Connect Client Instagram Page
              </h6>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"></button>
            </div>
            <div className="modal-body">
              <form>
                <div className="form-group">
                  <div className={'content-title-entitys'}>
                    <label htmlFor="formGroupExampleInput" className="d-block">
                      What's the client Instagram page?
                    </label>
                    <span className={'counter-entity'}>
                      {state.optionsData.length}
                    </span>
                  </div>
                  <Select
                    options={state.optionsData.map((option, idx) => ({
                      value: option.id,
                      label: option.ig_name,
                      icon: option.tasks ? 'check' : 'check'
                    }))}
                    components={{ Option: CustomSelectOption }}
                    onChange={handleSelectChanges}
                    isSearchable
                    isClearable
                  />
                </div>
              </form>
              {isAuthenticated &&
                userInfo.role === 'superAdmin' &&
                (isFailing &&
                props?.currentClient?.clientSocialNetworks?.instagramPage ? (
                  <div
                    style={{
                      textAlign: 'center',
                      color: isLoading ? 'black' : 'red'
                    }}>
                    <p style={{ verticalAlign: 'middle', display: 'inline' }}>
                      Mark as resolved{' '}
                    </p>
                    <input
                      style={{
                        verticalAlign: 'middle',
                        width: '19px',
                        height: '19px'
                      }}
                      type="checkbox"
                      id="customCheck4"
                      onChange={() => {
                        handleResolved('instagram');
                      }}
                      value={!isFailing}
                      disabled={isLoading}
                    />{' '}
                    if the social network is working again
                    {isLoading ? (
                      <div
                        className="spinner-border spinner-border-sm"
                        role="status">
                        <span className="sr-only">Loading...</span>
                      </div>
                    ) : (
                      <span style={{ fontSize: '20px' }}> ⚠️ </span>
                    )}
                  </div>
                ) : null)}
            </div>

            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary tx-13"
                data-bs-dismiss="modal"
                ref={(button) => {
                  setModalDismiss(button);
                }}>
                Close
              </button>
              <ButtonLoad
                state={state.savingClient}
                btn={'btn-primary'}
                icon={'fa-save'}
                text={' Save Client'}
                callback={() => {
                  instagramPageConnectionHandler();
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
});

export default NewInstagramConnection;
