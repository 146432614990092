import React, { useState, useCallback } from 'react';
import GoogleLogin from 'react-google-login';
import axios from 'axios';
import Swal from 'sweetalert2';
import { API_GOOGLE_LOCATIONS, NEW_ONBOARDING_ENABLED } from '../../utils';
import { authSelector } from '../../store/slices/auth';
import { useSelector } from 'react-redux';
import FacebookSyncModal from './FacebookSyncModal';
import InstagramSyncModal from './InstagramSyncModal';
import SupportModal from './SupportModal';
import { useHistory } from 'react-router-dom'; // Usamos useHistory en lugar de useNavigate
import './ButtonsGroup.scss';

const ButtonsGroup = ({
  connectCallback,
  view,
  viewState,
  userRole,
  showTotal,
  loading,
  handleModal = () => {}
}) => {
  const { userInfo } = useSelector(authSelector);
  const [sync, setSync] = useState(false);
  const [openFacebookSyncModal, setOpenFacebookSyncModal] = useState(false);
  const [openInstagramSyncModal, setOpenInstagramSyncModal] = useState(false);
  const [openSupportModal, setOpenSupportModal] = useState(false);
  const history = useHistory(); // Cambiar a useHistory

  const handleFacebookSyncModal = useCallback((open) => {
    setOpenFacebookSyncModal(!open);
  }, []);

  const handleInstagramSyncModal = useCallback((open) => {
    setOpenInstagramSyncModal(!open);
  }, []);

  const handleSupportModal = useCallback((open) => {
    setOpenSupportModal(!open);
  }, []);

  const googleHandler = (response) => {
    setSync(true);
    const googleUrl = API_GOOGLE_LOCATIONS;
    const googleCode = response.code;

    axios({
      method: 'POST',
      url: googleUrl,
      data: {
        code: googleCode
      },
      headers: {
        Authorization: `bearer ${userInfo.accessToken}`
      }
    })
      .then((response) => {
        if (response.status !== 200) {
          Swal.fire({
            icon: 'error',
            title: 'Error!',
            text: response.data.message
          });
          return;
        } else {
          setSync(false);
          Swal.fire({
            icon: 'success',
            title: 'Complete sync with Google My Business',
            showConfirmButton: false,
            timer: 3500
          });
        }
        connectCallback('googleLocations', response['data']['locations']);
      })
      .catch((error) => {
        setSync(false);
        console.error('Error has ocurred', error);
      });
  };

  const handleNavigation = () => {
    history.push('/new-client-onboarding');
  };

  return (
    <>
      {sync ? (
        <div
          className="progress position-fixed w-100"
          style={{
            height: '3px',
            top: 0,
            left: 0,
            zIndex: 1000,
            borderRadius: 0
          }}>
          <div className="progress-bar indeterminate" />
        </div>
      ) : (
        ''
      )}
      <div
        className="btn-group"
        role="group"
        aria-label="Basic example"
        style={{ ...(showTotal ? { minWidth: '352px' } : {}) }}>
        {view === 1 ? (
          <>
            <div>
              {userInfo.role === 'superAdmin' ? (
                <a
                  href="#bulkToAll"
                  className="btn btn-sm pd-x-15 btn-primary btn-uppercase mg-l-5"
                  data-toggle="modal"
                  data-animation="effect-scale"
                  data-backdrop="static"
                  data-keyboard="false">
                  <i data-feather="plus" className="wd-10 mg-r-5" />
                  Bulk To All
                </a>
              ) : null}

              <a
                href="#addNewGroup"
                className="btn btn-sm pd-x-15 btn-primary btn-uppercase mg-l-5"
                data-toggle="modal"
                data-animation="effect-scale"
                data-backdrop="static"
                data-keyboard="false">
                <i data-feather="plus" className="wd-10 mg-r-5" />
                Add New Group
              </a>
            </div>
          </>
        ) : view === 'contentStrategies' ? (
          <span
            className="btn btn-sm pd-x-15 btn-primary btn-uppercase mg-l-5"
            onClick={() => handleModal(true)}>
            Create Content Strategy
          </span>
        ) : view === 'reviews' ? (
          userRole === 'superAdmin' ? (
            <span
              className={`btn btn-sm pd-x-15 ${
                viewState?.showGoogleReviews
                  ? 'btn-outline-danger'
                  : 'btn-outline-primary'
              } mg-l-5 ${loading ? 'disabled' : ''}`}
              onClick={() => !loading && handleModal()}>
              {viewState?.showGoogleReviews
                ? 'Show Yelp Reviews'
                : 'Show Google Reviews'}
            </span>
          ) : null
        ) : view === 'notifications' ? (
          <></>
        ) : (
          <div className="btn-group" role="group" aria-label="Basic example">
            <div className="dropdown">
              {userRole === 'admin' || userRole === 'superAdmin' ? (
                <button
                  className="btn btn-sm pd-x-15 btn-primary btn-uppercase mg-r-10 dropdown-toggle"
                  type="button"
                  id="dropdownMenuButton"
                  data-bs-toggle="dropdown" // Cambiado a data-bs-toggle
                  aria-haspopup="true"
                  aria-expanded="false">
                  Connect Social Networks
                </button>
              ) : (
                []
              )}

              <div
                className="dropdown-menu"
                aria-labelledby="dropdownMenuButton">
                <button
                  className="dropdown-item"
                  onClick={() =>
                    handleFacebookSyncModal(openFacebookSyncModal)
                  }>
                  Connect Facebook Account
                </button>
                <FacebookSyncModal
                  open={openFacebookSyncModal}
                  handleModal={handleFacebookSyncModal}
                />

                <button
                  className="dropdown-item"
                  onClick={() =>
                    handleInstagramSyncModal(openInstagramSyncModal)
                  }>
                  Connect Instagram Account
                </button>
                <InstagramSyncModal
                  open={openInstagramSyncModal}
                  handleModal={handleInstagramSyncModal}
                />

                <GoogleLogin
                  clientId="968920648651-23di9ci83j1md3k0bah3t4g18br1vo24.apps.googleusercontent.com"
                  render={(renderProps) => (
                    <button
                      className="dropdown-item"
                      onClick={renderProps.onClick}
                      disabled={renderProps.disabled}>
                      Connect Google Business Account
                    </button>
                  )}
                  buttonText="Login"
                  onSuccess={googleHandler}
                  onFailure={(e) => {
                    Swal.fire({
                      icon: 'info',
                      title: 'Google Sync Cancelled',
                      showConfirmButton: false,
                      timer: 3500,
                      error: e
                    });
                  }}
                  cookiePolicy={'single_host_origin'}
                  scope={
                    'email https://www.googleapis.com/auth/plus.business.manage'
                  }
                  accessType={'offline'}
                  responseType={'code'}
                  prompt={'consent'}
                />

                <button
                  className="dropdown-item"
                  onClick={() => handleSupportModal(openSupportModal)}>
                  Support
                </button>
                <SupportModal
                  open={openSupportModal}
                  handleModal={handleSupportModal}
                />
              </div>
            </div>
            {(userInfo?.role === 'superAdmin' ||
              userInfo?.email === 'christiana@adlersocial.com' ||
              userInfo?.email === 'esther@adlersocial.com' ||
              userInfo?.email === 'emily@adlersocial.com' ||
              userInfo?.email === 'zachary@adlersocial.com' ||
              userInfo?.email === 'vasariah@adlersocial.com' ||
              userInfo?.email === 'heather@adlersocial.com') &&
            NEW_ONBOARDING_ENABLED ? (
              <span
                className="btn btn-sm pd-x-15 btn-primary btn-uppercase mg-l-5"
                onClick={handleNavigation}>
                Add New Client
              </span>
            ) : (
              <a
                href="#addNewClient"
                className="btn btn-sm pd-x-15 btn-primary btn-uppercase mg-l-5"
                data-toggle="modal"
                data-animation="effect-scale"
                data-backdrop="static"
                data-keyboard="false">
                Add New Client
              </a>
            )}
          </div>
        )}
      </div>
    </>
  );
};

export default ButtonsGroup;
