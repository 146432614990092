import React, { useEffect, useState, useRef } from 'react';
import {
  API_CLIENTS,
  API_KEY,
  API_FACEBOOK_PAGES
  // LINKS_CLIENTS
} from '../../utils/constants';
import ReactDOM from 'react-dom';
import { useSelector } from 'react-redux';
import axios from 'axios';
import Swal from 'sweetalert2';
import ButtonLoad from '../ButtonLoad';
import Select from 'react-select';
import DoughnutChart from '../DoughnutChart/DoughnutChart';
import ProgressBar from '../ProgressBar';
import readXlsxFile from 'read-excel-file';
import xlsx from 'xlsx';
import { v4 as uuidv4 } from 'uuid';
import moment from 'moment-timezone';
import './BulkModal.scss';
import AWS_Lambda from '../../aws/aws';

const BulkToAll = ({
  title,
  currentClientId,
  currentClientSocialNetworks,
  currentClientTimeZone,
  currentClients,
  currentCustomerName
}) => {
  const { userInfo } = useSelector((state) => state.auth);
  const [isBulking, setBulking] = useState(false);
  const [selectedClients, setSelectedClients] = useState([]);
  const [dropdownClients, setDropdownClients] = useState([]);
  const [progress, setProgress] = useState(0);
  const modalDismiss = useRef(null);
  const formBulk = useRef(null);
  const resetForm = useRef(null);
  const fileBulk = useRef(null);
  const clientsSelected = useRef(null);
  const checkFb = useRef(null);
  const checkGmb = useRef(null);
  const checkTw = useRef(null);
  const checkIg = useRef(null);
  let errorList = [];
  const undercoverGetClients = async () => {
    try {
      return await axios
        .all([
          axios.get(API_CLIENTS, { headers: { 'X-API-KEY': API_KEY } }),
          axios.get(API_FACEBOOK_PAGES, {
            headers: {
              Authorization: `bearer ${userInfo.accessToken}`
            }
          })
        ])
        .then(
          axios.spread(async (response, response3) => {
            const clients = response.data.clients;

            // let params = {
            //   FunctionName: LINKS_CLIENTS,
            //   Payload: JSON.stringify({ httpMethodLambda: 'GET' })
            // };
            // let links = await AWS_Lambda.invoke(params).promise();
            // links = JSON.parse(links['Payload']);
            // links = JSON.parse(links['body']);
            // links = links['Items'];

            let clientsListWithGroups = clients.map(function (client, index) {
              let countG = null;
              let likesPerClient;
              let reachedPerClient;
              let engagementPerClient;

              response3.data.pages.forEach((datos) => {
                if (
                  client.active !== false &&
                  client.socialnetworks.facebookPage === datos.entity_name
                ) {
                  likesPerClient = {
                    client: client.clientName,
                    likes: datos.country_page_likes
                  };
                  reachedPerClient = {
                    client: client.clientName,
                    reached: datos.reached,
                    reachedPercentage: datos.reachedPercentage
                  };
                  engagementPerClient = {
                    client: client.clientName,
                    engagement: datos.engament,
                    engagementPercentage: datos.engamentPercentage
                  };
                }
              });

              if (typeof client.gmbId === 'undefined') {
                countG = 0;
              } else {
                countG = 0;
                // links.filter(function (r) {
                //   return r.id === client.gmbId;
                // });
              }

              let countG2 = countG[0];
              let countY = null;

              if (typeof client.gmbId === 'undefined') {
                countY = 0;
              } else {
                countY = 0;
                //   links.filter(function (r) {
                //   return r.id === client.yelpId;
                // });
              }

              let countY2 = countY[0];

              let clientGrp = null;

              if (typeof client.clientGroups === 'undefined') {
                clientGrp = 'None';
              }

              if (typeof client.clientGroups === 'object') {
                if (client.clientGroups.hasOwnProperty('groupName')) {
                  clientGrp = client.clientGroups.groupName;
                } else {
                  let groupsFromArray = client.clientGroups.map(
                    (item) => item.groupName
                  );
                  clientGrp = groupsFromArray.join(', ');
                }
              }

              return {
                id: client.id,
                clientName: client.clientName,
                clientGroups: clientGrp,
                clientSocialNetworks: client.socialnetworks,
                timeZone: client.timeZone,
                bestTimes: client.bestTimes,
                appActive: client.appActive,
                appUid: client.appUid,
                smsCount: client.smsCount,
                mergeFields: client.mergeFields,
                gmbId: client.gmbId,
                yelpId: client.yelpId,
                countGmb: countG2,
                countYelp: countY2,
                likesFacebook: likesPerClient,
                reachedFacebook: reachedPerClient,
                engagementFacebook: engagementPerClient
              };
            });
            return clientsListWithGroups;
          })
        )
        .catch(function (error) {
          console.error('error getting clients', error);
          return [];
        });
    } catch (error) {
      if (!error.response) {
        throw error;
      }
      return null;
    }
  };

  useEffect(() => {
    function findCommonElements3(arr1, arr2) {
      return arr1.some((item) => arr2.includes(item));
    }
    if (currentClients) {
      let arr = currentClients;
      arr = arr.filter((item) => {
        let groups = item.clientGroups.split(',');
        let prohibitedGr = ['Test', 'New Client', 'App Only', 'None'];
        groups = groups.map((group) => {
          return group.trimStart();
        });
        return !findCommonElements3(groups, prohibitedGr);
      });

      arr = arr.map((client, index) => {
        return {
          value: client.id,
          label: client.clientName
        };
      });

      setSelectedClients(arr);
      setDropdownClients(arr);
    }
  }, [currentClients]);
  //THIS FUNCTIONS ARE FOR VALIDATE MERGEFIELDS
  async function validateMergeFields(client, posts) {
    for await (let post of posts) {
      let message =
        post['All networks message'] || post['All networks Message'];
      Object.keys(client.mergeFields).reduce((prev, current) => {
        if (message.includes(current) && client.mergeFields[current] === '') {
          errorList.push(`${client.clientName}  <b> -> ${current}</b>`);

          return null;
        }

        return prev.replace(
          new RegExp(current, 'gi'),
          client.mergeFields[current]
        );
      }, message);
    }
  }
  async function validateGroup(clients, file) {
    let defResult = [true, null];
    let statusRes = false;
    const buf = Buffer.from(file, 'base64');
    const wb = xlsx.read(buf, { type: 'buffer' });
    const ws = wb.Sheets['Hoja 1'] || wb.Sheets['Sheet1'];
    const posts = xlsx.utils.sheet_to_json(ws);
    const result = await Promise.allSettled(
      clients.map(async (client) => {
        let iClient = client;
        return await validateMergeFields(iClient, posts);
      })
    );
    if (errorList.length > 0) {
      statusRes = true;
    }
    defResult = [statusRes, result];
    return defResult;
  }
  function getBase64Second(file) {
    return new Promise(function (resolve, reject) {
      let reader = new FileReader();

      reader.onload = function () {
        let base64File = reader.result.split(';base64,')[1];
        resolve(base64File);
      };
      reader.onerror = reject;
      reader.readAsDataURL(file);
    });
  }

  const fileToBase64 = async (file, fileColumns, clients, bulkId) => {
    file = file.files[0];
    let bulkData = {};
    function getBase64(file) {
      return new Promise(function (resolve, reject) {
        let reader = new FileReader();
        reader.onload = function () {
          let facebook = checkFb?.current ? checkFb.current.checked : false;
          let twitter = checkTw?.current ? checkTw?.current.checked : false;
          let gmb = checkGmb?.current ? checkGmb.current.checked : false;
          let ig = checkIg?.current ? checkIg.current.checked : false;
          let base64File = reader.result.split(';base64,')[1];

          bulkData['file'] = base64File;
          bulkData['facebook'] = facebook;
          bulkData['twitter'] = twitter;
          bulkData['googleMyBusiness'] = gmb;
          bulkData['instagram'] = ig;
          bulkData['clientId'] = currentClientId || false;
          if (clients) {
            bulkData['clients'] = clients;
          }
          bulkData['dateNavigator'] = new Date();
          bulkData['currentClientTimeZone'] = currentClientTimeZone;
          bulkData['bulkId'] = bulkId;
          bulkData['bulkColumns'] = fileColumns - 1;
          bulkData['createdAt'] = moment.utc().unix();
          bulkData['createdBy'] = currentCustomerName;
          if (!(gmb || twitter || facebook || ig)) {
            Swal.fire({
              title: 'Error!',
              text: 'Please select a social network.'
            });
            setBulking(false);

            return;
          }
          resolve(bulkData);
        };
        reader.onerror = reject;
        reader.readAsDataURL(file);
      });
    }
    let response = await getBase64(file);
    return response;
  };

  const checkFormatBulk = async (file) => {
    let res = await readXlsxFile(file).then(async (rows) => {
      if (rows[0].length !== 9) {
        return false;
      }
      if (
        rows[0][0] !== 'Year' ||
        rows[0][1] !== 'Month' ||
        rows[0][2] !== 'Day' ||
        rows[0][3] !== 'Hour' ||
        rows[0][4] !== 'Minute' ||
        rows[0][5] !== 'AmPm' ||
        rows[0][6] !== 'All networks message' ||
        rows[0][7] !== 'Attached image' ||
        rows[0][8] !== 'Is Draft?'
      ) {
        let msg = `The file has the wrong column headers.<br/><br/>Remember:<br/><small>Year, Month, Day, Hour, Minute, AmPm,<br/> All networks message, Attached image, Is Draft?.</small>`;
        return { valid: false, msg };
      }
      for (let index = 1; index < rows.length; index++) {
        const row = rows[index];
        let year = row[0];
        let month = row[1];
        let day = row[2];
        let hour = row[3];
        let min = row[4];
        let ampm = row[5];
        let message = row[6];
        let url = row[7];
        let isDraft = row[8];

        if (
          typeof year !== 'number' ||
          parseInt(year) > 2025 ||
          parseInt(year) < 2021
        ) {
          let msg = `Wrong year "<strong>${year}</strong>" in row ${
            index + 1
          } .`;
          return { valid: false, msg };
        }
        if (
          typeof month !== 'number' ||
          parseInt(month) > 12 ||
          parseInt(month) < 1
        ) {
          let msg = `Wrong month "<strong>${month}</strong>" in row ${
            index + 1
          }.`;
          return { valid: false, msg };
        }
        if (
          typeof day !== 'number' ||
          parseInt(day) > 31 ||
          parseInt(day) < 1
        ) {
          let msg = `Wrong day "<strong>${day}</strong>" in row ${index + 1}.`;
          return { valid: false, msg };
        }
        if (
          typeof hour !== 'number' ||
          parseInt(hour) > 12 ||
          parseInt(hour) < 1
        ) {
          let msg = `Wrong hour "<strong>${hour}</strong>" in row ${
            index + 1
          }.`;
          return { valid: false, msg };
        }
        if (
          typeof min !== 'number' ||
          parseInt(min) > 60 ||
          parseInt(min) < 0
        ) {
          let msg = `Wrong minute "<strong>${min}</strong>" in row ${
            index + 1
          }.`;
          return { valid: false, msg };
        }
        if (ampm !== 'am' && ampm !== 'pm') {
          let msg = `Wrong AmPm "<strong>${ampm}</strong>" in row ${
            index + 1
          }.`;
          return { valid: false, msg };
        }
        if (typeof message !== 'string' || message.length > 500) {
          let msg = `Wrong message in row ${index + 1}.`;
          return { valid: false, msg };
        }

        if (url !== null) {
          let res = await imageExists(url);
          if (res < 200 || res > 300) {
            let msg = `Bad url in row ${index + 1}.`;
            return { valid: false, msg };
          }
        } else {
          if (isDraft !== null) {
            if (String(isDraft).toLowerCase().trim() !== 'true') {
              let msg = `Bad isDraft attribute in row ${index + 1}.`;
              return { valid: false, msg };
            }
          } else {
            let msg = `Bad url or select isDraft attribute in row ${
              index + 1
            }.`;
            return { valid: false, msg };
          }
        }
      }
      return { valid: true };
    });
    return res;
  };

  const checkColumnsBulk = async (file) => {
    let res = await readXlsxFile(file).then(async (data) => {
      return data.length;
    });

    return res;
  };

  const imageExists = (image_url) => {
    const proxyurl = 'https://adlersocial-cors-anywhere.herokuapp.com/';
    let res = fetch(proxyurl + image_url)
      .then((response) => {
        return response.status;
      })
      .catch((err) => {
        console.log(err);
        return 200;
      });

    return res;
  };

  const submitForm = async (e) => {
    e.preventDefault();
    setBulking(true);

    let setClients = clientsSelected.current
      ? clientsSelected.current.props.value
      : undefined;
    if (selectedClients.length === 0) {
      Swal.fire({
        title: 'Error!',
        text: 'Please select almost 1 customer.'
      }).then(() => {
        setBulking(false);

        setProgress(0);
      });
      return;
    }

    if (currentClientId !== undefined || setClients.length > 0) {
      if (fileBulk.current.files[0] === undefined) {
        Swal.fire({
          title: 'Error!',
          text: 'Please add the file.'
        }).then(() => {
          setBulking(false);

          setProgress(0);
        });
        return;
      }
      let fileOk = await checkFormatBulk(fileBulk.current.files[0]);
      if (!fileOk.valid) {
        Swal.fire({
          title: 'Error!',
          html: '<p>' + fileOk.msg + '</p>'
        }).then(() => {
          setBulking(false);

          setProgress(0);
        });
        fileBulk.current.innerHTML = '';
        return;
      }

      let fileColumns = await checkColumnsBulk(fileBulk.current.files[0]);
      let response = await getBase64Second(fileBulk.current.files[0]);
      function findCommonElements3(arr1, arr2) {
        return arr1.some((item) => arr2.includes(item));
      }
      let allCl = await undercoverGetClients();
      if (allCl) {
        let arr = allCl;
        arr = arr.filter((item) => {
          let groups = item.clientGroups.split(',');
          let prohibitedGr = ['Test', 'New Client', 'App Only', 'None'];
          groups = groups.map((group) => {
            return group.trimStart();
          });
          return !findCommonElements3(groups, prohibitedGr);
        });
        let error2 = await validateGroup(arr, response);
        let htmlForMessage =
          '<p><b>Error, this customers has errors in Mergefields</b></p>';

        for (let i = 0; i < errorList.length; i++) {
          htmlForMessage += `<li>${errorList[i]}</li>`;
        }

        if (error2[0]) {
          Swal.fire({
            allowOutsideClick: false,
            icon: 'error',
            html: `<ol>${htmlForMessage}</ol>`
          }).then(() => {
            setBulking(false);

            setProgress(0);
          });
          return;
        }
      }

      const ejecutar = async (clients, index, fileColumns, bulkId) => {
        let bulkData = await fileToBase64(
          fileBulk.current,
          fileColumns,
          clients,
          bulkId
        );
        if (bulkData) {
          let params = {
            FunctionName: process.env.REACT_APP_BULK_TO_ALL_LAMBDA, // the lambda function we are going to invoke
            Payload: JSON.stringify({ body: JSON.stringify(bulkData) })
          };

          return AWS_Lambda.invoke(params).promise();
        } else {
          return;
        }
      };

      let limit = Math.ceil((setClients.length - 1) / 90);
      let list_listas = [];
      let step = 0;
      for (let x = 0, end = 90; x < limit; x++, end += 90) {
        let individual_list = [];

        for (; step < end; step++) {
          if (setClients[step] !== undefined) {
            individual_list.push(setClients[step]);
          }
        }
        list_listas.push(individual_list);
      }
      let result = [];
      let temp_res = 'nothing';
      const bulkId = uuidv4();
      for (let iterator = 0; iterator < list_listas.length; iterator++) {
        temp_res = ejecutar(
          list_listas[iterator],
          iterator,
          fileColumns,
          bulkId
        );
        result.push(temp_res);
      }
      let total = Math.ceil(selectedClients.length / 120);
      let prog = 1;
      function tick(promise) {
        promise.then(function () {
          prog++;
          setProgress(Math.ceil((100 / total) * prog));
        });
        return promise;
      }
      let final = await Promise.all(result.map(tick));
      if (final[0] === undefined) {
        return;
      }
      final = final.map((item) => {
        let body = JSON.parse(JSON.parse(item.Payload).body);

        return body;
      });
      final = final.map((item) => {
        return {
          postSucceded: item['post-succeded'],
          postError: item['post-error'],
          postPublished: item['post-published']
        };
      });

      let succeed = 0,
        error = 0,
        published = 0;
      final.forEach(function (d) {
        succeed += d.postSucceded;
        error += d.postError;
        published += d.postPublished;
      });
      let obj2 = {
        'post-succeded': succeed,
        'post-error': error,
        'post-published': published
      };

      let wrapper = document.createElement('div');

      ReactDOM.render(<DoughnutChart data={obj2} />, wrapper);
      let chart = wrapper.firstChild;
      Swal.fire({
        title: 'Success, Bulk finished!.',
        type: 'success',
        html: chart,
        confirmButtonText: 'Great!',
        customClass: { confirmButton: 'adler-outline-btn pd-x-30' }
      });
      setBulking(false);
      if (resetForm?.current !== null) {
        resetForm.current.click();
      }
      setProgress(0);
      if (modalDismiss?.current !== undefined) {
        modalDismiss.current.click();
      }
    }
  };

  // Here we update the state depending on adding or removing elements
  const handleChange = (value, { action, removedValue }) => {
    switch (action) {
      // Remove selected option
      case 'remove-value':
        setSelectedClients(
          selectedClients.filter((val) => val !== removedValue)
        );
        setDropdownClients([...dropdownClients, removedValue]);
        break;
      // Add a selected option
      case 'select-option':
        setSelectedClients([...selectedClients, value[value.length - 1]]);
        setDropdownClients(
          dropdownClients.filter((val) => val !== value[value.length - 1])
        );
        break;
      // Clear selected options
      case 'clear':
        setSelectedClients([]);
        selectedClients.forEach((client) => {
          setDropdownClients([...dropdownClients, client]);
        });
        break;
      default:
        return;
    }
  };

  let viewPage = title === 'posts' ? 1 : 0;
  let clientSocialNetworks = !viewPage
    ? { facebook: true, twitter: true, googleMyBusiness: true, instagram: true }
    : currentClientSocialNetworks;

  return (
    <div>
      <div
        className="modal fade"
        id="bulkToAll"
        tabIndex={-1}
        role="dialog"
        aria-labelledby="bulkToAll"
        aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className={`modal-content tx-14 ${isBulking ? 'disable' : ''}`}>
            <div className="modal-header">
              <h6 className="modal-title" id="addNewGroupLabel">
                Bulk to All Customers ({selectedClients.length})
              </h6>
              <button
                type="button"
                className="btn-close"
                data-dismiss="modal"
                aria-label="Close"></button>
            </div>
            <form
              onSubmit={(e) => {
                e.preventDefault();
                if (!viewPage) {
                  return;
                }
                return submitForm;
              }}
              ref={formBulk}>
              <div className="modal-body">
                <div className="form-group">
                  <div className="">
                    <div className="card">
                      <div className="card-body">
                        <h5 className="card-title">Select your file</h5>
                        <h6 className="card-subtitle mb-2 text-muted">
                          Please add a file xls or xlsx for bulk {title}
                        </h6>
                        <input
                          className="btn-reset"
                          type="reset"
                          ref={resetForm}
                          value="reset"
                        />
                        <input
                          type="file"
                          ref={fileBulk}
                          className="form-control attach-file"
                          name="fileBulk"
                          accept=".xls,.xlsx"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                {!viewPage ? (
                  <div className="form-group mg-x-20">
                    {
                      <Select
                        ref={clientsSelected}
                        onChange={handleChange}
                        options={dropdownClients}
                        value={selectedClients}
                        isMulti
                        classNamePrefix="custom"
                      />
                    }
                  </div>
                ) : (
                  []
                )}

                {
                  <div className="content-sn-bulk">
                    {clientSocialNetworks.facebook && (
                      <div className="custom-control custom-checkbox">
                        <input
                          ref={checkFb}
                          type="checkbox"
                          className="custom-control-input"
                          id="bulkToAllCheckFb"
                          defaultChecked
                        />
                        <label
                          className="custom-control-label"
                          htmlFor="bulkToAllCheckFb">
                          Facebook
                        </label>
                      </div>
                    )}

                    {clientSocialNetworks.googleMyBusiness && (
                      <div className="custom-control custom-checkbox">
                        <input
                          ref={checkGmb}
                          type="checkbox"
                          className="custom-control-input"
                          id="bulkToAllCheckGmb"
                          defaultChecked
                        />
                        <label
                          className="custom-control-label"
                          htmlFor="bulkToAllCheckGmb">
                          Google My Business
                        </label>
                      </div>
                    )}

                    {clientSocialNetworks.twitter && (
                      <div className="custom-control custom-checkbox">
                        <input
                          ref={checkTw}
                          type="checkbox"
                          className="custom-control-input"
                          id="bulkToAllCheckTw"
                          defaultChecked
                        />
                        <label
                          className="custom-control-label"
                          htmlFor="bulkToAllCheckTw">
                          Twitter
                        </label>
                      </div>
                    )}
                    {clientSocialNetworks.instagram && (
                      <div className="custom-control custom-checkbox">
                        <input
                          ref={checkIg}
                          type="checkbox"
                          className="custom-control-input"
                          id="bulkToAllCheckIg"
                          defaultChecked
                        />
                        <label
                          className="custom-control-label"
                          htmlFor="bulkToAllCheckIg">
                          Instagram
                        </label>
                      </div>
                    )}
                  </div>
                }
              </div>

              <div className="modal-footer">
                <ProgressBar progress={progress} isActive={isBulking} />
                <button
                  type="button"
                  className="btn btn-secondary tx-13"
                  data-dismiss="modal"
                  ref={modalDismiss}>
                  Close
                </button>
                <ButtonLoad
                  state={isBulking}
                  text={'Bulk'}
                  icon={'fa-upload'}
                  btn={'btn-primary btn-bulk-group'}
                  type={'submit'}
                  callback={(e) => {
                    submitForm(e);
                  }}
                />
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BulkToAll;
