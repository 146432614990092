export const API_CLIENTS = process.env.REACT_APP_API_CLIENTS;
export const API_CLIENTS_EDIT = process.env.REACT_APP_API_CLIENTS_EDIT;
export const API_CLIENTS_DELETE = process.env.REACT_APP_API_CLIENTS_DELETE;
export const API_CLIENTS_NAME = process.env.REACT_APP_API_CLIENTS_NAME;
export const API_GROUPS = process.env.REACT_APP_API_GROUPS;
export const API_GROUPS_DELETE = process.env.REACT_APP_API_GROUPS_DELETE;
export const API_GOOGLE_LOCATIONS = process.env.REACT_APP_API_GOOGLE_LOCATIONS;
export const API_FACEBOOK_PAGES = process.env.REACT_APP_API_FACEBOOK_PAGES;
export const API_TWITTER_ACCOUNTS = process.env.REACT_APP_API_TWITTER_ACCOUNTS;
export const API_POSTS = process.env.REACT_APP_API_POSTS;
export const API_POSTS_EDIT = process.env.REACT_APP_API_POSTS_EDIT;
export const API_POSTS_REPAIR = process.env.REACT_APP_API_POSTS_REPAIR;
export const API_ASSETS = process.env.REACT_APP_API_ASSETS;
export const API_BULK = process.env.REACT_APP_API_BULK;
export const API_BULK_STATUS = process.env.REACT_APP_API_BULK_STATUS;
export const API_BULK_TO_ALL_LAMBDA = process.env.REACT_APP_BULK_TO_ALL_LAMBDA;
export const API_POSTS_MONTH = process.env.REACT_APP_API_POSTS_MONTH;
export const API_POSTS_DELETE = process.env.REACT_APP_API_POSTS_DELETE;
export const API_POSTS_COUNT = process.env.REACT_APP_API_POSTS_COUNT;
export const APP_REGISTER = process.env.REACT_APP_APP_REGISTER;
export const APP_GET_CLIENT = process.env.REACT_APP_APP_GET_CLIENT;
export const API_KEY = process.env.REACT_APP_API_KEY;
export const REBRANDLY_APIKEY = process.env.REACT_APP_REBRANDLY_APIKEY;
export const REBRANDLY_WORKSPACE = process.env.REACT_APP_REBRANDLY_APIKEY;
export const LINKS_CLIENTS = process.env.REACT_APP_LINKS_CLIENTS;
export const RENDER_DEV = process.env.REACT_APP_RENDER_DEV;
export const AWS_KEY = process.env.REACT_APP_ACCESS_KEY;
export const AWS_SECRET = process.env.REACT_APP_SECRET_KEY;
export const AWS_REGION = process.env.REACT_APP_REGION;
export const S3_CLIENTS_IMAGES = process.env.REACT_APP_S3_CLIENTS_IMAGES;
export const AWS_BUCKET_MMS = process.env.REACT_APP_AWS_BUCKET_MMS;
export const MMS_GET_IMAGES = process.env.REACT_APP_MMS_GET_IMAGES;
export const AWS_BUCKET = process.env.REACT_APP_BUCKET_AWS;
export const AWS_S3URL = process.env.REACT_APP_S3_URL;
export const APP_S3 = process.env.REACT_APP_S3_APP;
export const AWS_MMS_S3_API_KEY = process.env.REACT_APP_AWS_MMS_S3_API_KEY;
export const AWS_MMS_S3_ACCESS_KEY =
  process.env.REACT_APP_AWS_MMS_S3_ACCESS_KEY;
export const API_SN_DELETE = process.env.REACT_APP_API_SN_DELETE;
export const MANIPULATE_DESCRIPTION =
  process.env.REACT_APP_MANIPULATE_DESCRIPTION;
export const API_THREE_LAST_REVIEWS =
  process.env.REACT_APP_GET_GMB_THREE_LAST_REVIEWS;
export const API_GET_NOTES = process.env.REACT_APP_API_GET_NOTES;
export const API_GET_LINKS = process.env.REACT_APP_API_GET_LINKS;
export const GET_ZOHO_ACCOUNTS = process.env.REACT_APP_API_GET_ZOHO_ACCOUNTS;
export const UPDATE_ZOHO_ACCOUNT =
  process.env.REACT_APP_API_UPDATE_ZOHO_ACCOUNT;
export const API_RESOLVE_FAILING_SN =
  process.env.REACT_APP_API_RESOLVE_FAILING_SN;
export const API_ADMIN_CLIENTS = process.env.REACT_APP_API_ADMIN_CLIENTS;
export const API_ASSIGN_CREATOR = process.env.REACT_APP_API_ASSIGN_CREATOR;
export const REACT_APP_API_NOTIFICATIONS =
  process.env.REACT_APP_API_NOTIFICATIONS;
export const API_CLEAN_NOTIFICATIONS =
  process.env.REACT_APP_API_CLEAN_NOTIFICATIONS;
export const API_GET_POSTS_INFO = process.env.REACT_APP_API_GET_POSTS_INFO;
export const API_AUTO_ASSIGN = process.env.REACT_APP_API_AUTO_ASSIGN;
export const REPORTS_FLAG = process.env.REACT_APP_REPORTS_FLAG;
export const REPORTS_START_YEAR = process.env.REACT_APP_REPORTS_START_YEAR;
export const REPORTS_DAILY = process.env.REACT_APP_REPORTS_DAILY;
export const REPORTS_MONTHLY = process.env.REACT_APP_REPORTS_MONTHLY;
export const FACEBOOK_SYNC = process.env.REACT_APP_FACEBOOK_SYNC;
export const API_REPLY_REVIEW = process.env.REACT_APP_API_REPLY_REVIEW;
export const INSTAGRAM_SYNC = process.env.REACT_APP_INSTAGRAM_SYNC;
export const DELETE_IMAGES = process.env.REACT_APP_DELETE_IMAGES;
export const PARAPHRASING = process.env.PARAPHRASING;
export const GET_REVIEWS_PER_CLIENT =
  process.env.REACT_APP_GET_REVIEWS_PER_CLIENT;
export const GET_GROUP_PROMPS = process.env.REACT_APP_GET_GROUP_PROMPTS;
export const API_GENERATE_AI_POST = process.env.REACT_APP_API_GENERATE_AI_POST;
export const AWS_BUCKET_CLIENT_LOGOS =
  process.env.REACT_APP_AWS_BUCKET_CLIENT_LOGOS;
export const API_NEWCLIENT = process.env.REACT_APP_API_NEWCLIENT;
export const API_GET_CONTENT_STRATEGIES =
  process.env.REACT_APP_API_GET_CONTENT_STRATEGIES;
export const API_CREATE_CONTENT_STRATEGIES =
  process.env.REACT_APP_API_CREATE_CONTENT_STRATEGIES;
export const NEW_ONBOARDING_ENABLED =
  process.env.REACT_APP_NEW_ONBOARDING_ENABLED;
export const API_REGEN_AI_POST_CONTENT =
  process.env.REACT_APP_API_REGEN_AI_POST_CONTENT;
export const API_EDIT_PROMPT = process.env.REACT_APP_API_EDIT_PROMPT;
export const API_GET_PROMPTS = process.env.REACT_APP_API_GET_PROMPTS;
export const API_GET_CS_IMAGES = process.env.REACT_APP_GET_CS_IMAGES;
export const API_DELETE_CS_IMAGE = process.env.REACT_APP_DELETE_CS_IMAGE;
export const API_GET_CS_LIST = process.env.REACT_APP_GET_CS_LIST;
export const BUCKET_CS_IMAGES_UPLOAD =
  process.env.REACT_APP_BUCKET_CS_IMAGES_UPLOAD;
export const UPDATE_STOCK_UPLOAD_IMAGES =
  process.env.REACT_APP_UPDATE_STOCK_UPLOAD_IMAGES;
export const UPDATE_STOCK_CLIENT_UPLOAD_IMAGES =
  process.env.REACT_APP_UPDATE_STOCK_CLIENT_UPLOAD_IMAGES;
export const DELETE_CL_IMAGE = process.env.REACT_APP_DELETE_CL_IMAGE;
export const LIST_CL_IMAGES = process.env.REACT_APP_LIST_CL_IMAGES;
export const LIST_CL_IMAGES_GOOGLE =
  process.env.REACT_APP_LIST_CL_IMAGES_GOOGLE;
export const CS_COUNT_CLIENTS = process.env.REACT_APP_CS_COUNT_CLIENTS;
export const MONTHS_TO_GENERATE = process.env.REACT_APP_MONTHS_TO_GENERATE;
export const GET_PRIMARY_SERVICES = process.env.REACT_APP_GET_PRIMARY_SERVICES;
export const API_REVIEW_APPROVE = process.env.REACT_APP_API_REVIEW_APPROVE;
export const API_REVIEW_UPDATE_MSG_REPLY =
  process.env.REACT_APP_API_REVIEW_UPDATE_MSG_REPLY;
export const API_GET_GOOGLE_PROFILE_INFO =
  process.env.REACT_APP_API_GET_GOOGLE_PROFILE_INFO;
export const API_UPDATE_GOOGLE_PROFILE_INFO =
  process.env.REACT_APP_API_UPDATE_GOOGLE_PROFILE_INFO;
export const API_GET_LOGS = process.env.REACT_APP_API_GET_LOGS;
export const SHOW_LOGS = process.env.REACT_APP_SHOW_LOGS;
export const API_GET_CATEGORIES = process.env.REACT_APP_API_GET_CATEGORIES;
export const GCP_API_KEY = process.env.REACT_APP_GCP_API_KEY;
export const API_GET_GOOGLE_REVIEWS_V2 =
  process.env.REACT_APP_API_GET_GOOGLE_REVIEWS_V2;
export const API_GET_YELP_REVIEWS = process.env.REACT_APP_API_GET_YELP_REVIEWS;
export const API_GET_PLANS = process.env.REACT_APP_API_GET_PLANS;
export const API_SUPPORT = process.env.REACT_APP_API_SUPPORT;
export const API_UPLOAD_S3 = process.env.REACT_APP_API_UPLOAD_S3;
