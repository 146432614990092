import React, { useEffect, useState, forwardRef } from 'react';
import Select, { components } from 'react-select';
import {
  API_GOOGLE_LOCATIONS,
  API_RESOLVE_FAILING_SN
} from '../utils/constants';
import { useSelector } from 'react-redux';
import Swal from 'sweetalert2';
import ButtonLoad from './ButtonLoad';
import { authSelector } from '../store/slices/auth';

const axios = require('axios');
const { Option } = components;
const CustomSelectOption = (props) => (
  <Option {...props}>
    <span
      style={{ color: props.data.icon === 'times' ? 'firebrick' : 'green' }}>
      <i className={`fas fa-${props.data.icon}-circle`} />
    </span>
    &nbsp;
    {props.data.label}
  </Option>
);

const NewGoogleConnection = forwardRef((props, ref) => {
  const { isAuthenticated, userInfo } = useSelector(authSelector);
  const [_isMounted, _setIsMounted] = useState(true);
  const [state, setState] = useState({
    optionsData: null,
    loaded: false,
    googleLocation: null,
    currentClient: null,
    savingClient: false
  });
  const [isFailing, setIsFailing] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const handleResolved = async (snName) => {
    let config = {
      method: 'post',
      url: API_RESOLVE_FAILING_SN,
      data: { clientId: props.currentClientId, snName },
      headers: { 'Content-Type': 'application/json' }
    };

    if (isFailing) {
      setIsLoading(true);
      await axios(config)
        .then(async (response) => {
          if (response.status === 200) {
            setIsFailing(false);
            setIsLoading(false);
            await someFn();
          }
        })
        .catch((error) => {
          console.error(error);
          setIsLoading(false);
        });
    }
  };
  useEffect(() => {
    if (props?.currentClient?.clientSocialNetworks?.gmbFailing !== undefined) {
      setIsFailing(props?.currentClient?.clientSocialNetworks?.gmbFailing);
    } else {
      setIsFailing(false);
    }
  }, [props.currentClient, props.currentClientId]);
  // useEffect(() => {
  //   if (_isMounted) return getLocations();
  // });
  useEffect(() => {
    getLocations();
  }, []);

  function getLocations() {
    axios
      .get(API_GOOGLE_LOCATIONS, {
        headers: {
          Authorization: `bearer ${userInfo.accessToken}`
        }
      })
      .then(async (response) => {
        const pagesData = [];
        await response.data.pages.forEach((loct) => {
          pagesData.push(loct);
        });
        setState({ ...state, optionsData: pagesData, loaded: true });
      })
      .catch((error) => {
        console.error('error ' + error);
      });
  }

  const handleSelectChanges = (option) => {
    if (option) {
      const options = { id: option.value, locationName: option.label };
      setState({
        ...state,
        googleLocation: options,
        currentClient: props.currentClientId
      });
    }
  };

  const googlePageConnectionHandler = () => {
    setState({ ...state, savingClient: true });
    if (state.googleLocation === undefined) {
      Swal.fire({
        title: 'Error!',
        text: 'Please select a location.'
      }).then((r) => {
        setState({ ...state, savingClient: false });
      });
      return;
    }

    const bodyPut = {
      clientId: state.currentClient,
      googleLocationId: state.googleLocation.id,
      googleLocationName: state.googleLocation.locationName
    };

    const url = API_GOOGLE_LOCATIONS;

    axios
      .put(url, bodyPut, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `bearer ${userInfo.accessToken}`
        }
      })
      .then(async function (response) {
        if (response.status === 200) {
          setState({ ...state, savingClient: false });
          NewGoogleConnection.modalDismiss.click();
          _setIsMounted(false);
          await someFn(state.currentClient);
        }
      })
      .catch(function (response) {
        setState({ ...state, savingClient: false });
      });
  };

  const someFn = async () => {
    await props.connectSnCallback();
  };

  const { loaded, optionsData } = state;

  // if (!loaded) {
  //   return '';
  // }

  return (
    <div>
      <div
        className="modal fade"
        id="newGoogleConnection"
        tabIndex={-1}
        role="dialog"
        aria-labelledby="addNewClientLabel"
        aria-hidden="true"
        ref={ref}>
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content tx-14">
            <div className="modal-header">
              <h6 className="modal-title" id="addNewClientLabel">
                Connect Client Google My Business Location
              </h6>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"></button>
            </div>
            <div className="modal-body">
              <form>
                <div className="form-group">
                  <div className={'content-title-entitys'}>
                    <label htmlFor="formGroupExampleInput" className="d-block">
                      What's the client google my business location?
                    </label>
                    <span className={'counter-entity'}>
                      {optionsData?.length}
                    </span>
                  </div>
                  <Select
                    options={optionsData?.map((option, idx) => ({
                      value: option.id,
                      label: option.locationName
                    }))}
                    components={{ Option: CustomSelectOption }}
                    onChange={handleSelectChanges}
                    isSearchable
                    isClearable
                  />
                </div>
              </form>
              {isAuthenticated &&
                userInfo.role === 'superAdmin' &&
                (isFailing &&
                props?.currentClient?.clientSocialNetworks?.googleMyBusiness ? (
                  <div
                    style={{
                      textAlign: 'center',
                      color: isLoading ? 'black' : 'red'
                    }}>
                    <p style={{ verticalAlign: 'middle', display: 'inline' }}>
                      Mark as resolved{' '}
                    </p>
                    <input
                      style={{
                        verticalAlign: 'middle',
                        width: '19px',
                        height: '19px'
                      }}
                      type="checkbox"
                      id="customCheck4"
                      onChange={() => {
                        handleResolved('googleMyBusiness');
                      }}
                      value={!isFailing}
                      disabled={isLoading}
                    />{' '}
                    if the social network is working again
                    {isLoading ? (
                      <div
                        className="spinner-border spinner-border-sm"
                        role="status">
                        <span className="sr-only">Loading...</span>
                      </div>
                    ) : (
                      <span style={{ fontSize: '20px' }}> ⚠️ </span>
                    )}
                  </div>
                ) : null)}
            </div>

            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary tx-13"
                data-bs-dismiss="modal"
                ref={(button) => {
                  NewGoogleConnection.modalDismiss = button;
                  _setIsMounted(false);
                }}>
                Close
              </button>
              <ButtonLoad
                state={state.savingClient}
                btn={'btn-primary'}
                icon={'fa-save'}
                text={' Save Client'}
                callback={() => {
                  googlePageConnectionHandler();
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
});

export default NewGoogleConnection;
