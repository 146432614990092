import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import ImageLibrary from './ImageLibrary/ImageLibrary';
import NotesSection from './NotesSection';
import ReviewsSection from './ReviewsSection';
import GoogleBusinessProfileSection from './GoogleBusinessProfileSection/GoogleBusinessProfileSection';
import AboutOnboarding from './AboutSection/AboutOnboarding';
import ContentOnboarding from './ContentSection/ContentOnboarding';
import TooltipInfo from './TooltipInfo/tooltipInfo';
import './Client.scss';

const Sections = ({ clientDetails, selectedClient, breadcrumbs }) => {
  const [viewSelect, setViewSelect] = useState(1);
  const history = useHistory();

  if (!clientDetails || !selectedClient) {
    return <div>Loading...</div>;
  }
  const clientName = clientDetails.clientName;

  const encodeClientName = (clientName) => {
    return clientName
      .replace(/\s+/g, '%20')
      .replace(/\//g, '%2F')
      .replace(/'/g, '%1D')
      .replace(/’/g, '%1E');
  };

  const encodedClientName = encodeClientName(clientName);

  const redirectToCalendar = () => {
    history.push(`/calendar/${encodedClientName}`);
  };

  const RenderContent = () => {
    let contentView;

    switch (viewSelect) {
      case 6:
        contentView = <ImageLibrary selectedClient={selectedClient} />;
        break;
      case 5:
        contentView = <ReviewsSection id={clientDetails?.id} />;
        break;
      case 4:
        contentView = <GoogleBusinessProfileSection userData={clientDetails} />;
        break;
      case 3:
        contentView = <NotesSection id={clientDetails?.id} />;
        break;
      case 2:
        contentView = (
          <ContentOnboarding
            defaultData={clientDetails}
            callbackFromParent={() => {}}
          />
        );
        break;
      case 1:
        contentView = (
          <AboutOnboarding
            defaultData={clientDetails}
            callbackFromParent={() => {}}
          />
        );
        break;
      default:
        contentView = (
          <AboutOnboarding
            defaultData={clientDetails}
            callbackFromParent={() => {}}
          />
        );
        break;
    }

    return <div className="media-body h-100 p-2">{contentView}</div>;
  };

  return (
    <div>
      <div className="px-8">
        <div className="d-flex p-2 align-items-center justify-content-between">
          <div className="ps-2">
            {/* Breadcrumbs */}
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb breadcrumb-style1 mg-b-10">
                {breadcrumbs.map((crumb, index) => (
                  <li
                    key={index}
                    className={`breadcrumb-item ${
                      index === breadcrumbs.length - 1 ? 'active' : ''
                    }`}>
                    {index === breadcrumbs.length - 1 ? (
                      crumb.name
                    ) : (
                      <a href={crumb.link}>{crumb.name}</a>
                    )}
                  </li>
                ))}
              </ol>
            </nav>
            <h4
              className="mg-b-0 tx-spacing--1"
              style={{ display: 'flex', alignItems: 'center' }}>
              {clientName}
              <TooltipInfo
                accountOwner={clientDetails.accountOwner}
                accountStatus={clientDetails.accountStatus}
              />
            </h4>
          </div>

          {/* Navigation */}
          <div className="d-flex align-items-center justify-content-end ms-auto col-sm-6 col-md-5 col-lg-auto mt-2 section-explore ml-3">
            <nav className="nav nav-pills">
              <button
                onClick={() => setViewSelect(1)}
                className={`nav-link ${viewSelect === 1 ? 'active' : ''} px-3`}>
                <span>About</span>
              </button>
              <button
                onClick={() => setViewSelect(2)}
                className={`nav-link ${viewSelect === 2 ? 'active' : ''} px-3`}>
                <span>Content</span>
              </button>
              <button
                onClick={() => setViewSelect(3)}
                className={`nav-link ${viewSelect === 3 ? 'active' : ''} px-3`}>
                <span>Notes</span>
              </button>
              <button
                onClick={() => setViewSelect(4)}
                className={`nav-link ${viewSelect === 4 ? 'active' : ''} px-3`}>
                <span>GBP</span>
              </button>
              <button
                onClick={() => setViewSelect(5)}
                className={`nav-link ${viewSelect === 5 ? 'active' : ''} px-3`}>
                <span>Reviews</span>
              </button>
              <button
                onClick={() => setViewSelect(6)}
                className={`nav-link ${viewSelect === 6 ? 'active' : ''} px-3`}>
                <span>Image Library</span>
              </button>
            </nav>
          </div>

          <div
            className="btn-group ps-4"
            role="group"
            aria-label="Account and Calendar selector">
            <button type="button" className="btn btn-primary btn-inactive">
              Account
            </button>
            <button
              type="button"
              className="btn btn-light btn-active"
              onClick={redirectToCalendar}>
              Calendar
            </button>
          </div>
        </div>
        <RenderContent />
      </div>
    </div>
  );
};

export default Sections;
